import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const langOptions = 'en|cn'
const generatePath = (path) => `/:lang(${langOptions})?${path}`

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: generatePath('/about-us'),
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/recognized-list',
    name: 'RecognizedList',
    component: () => import('../views/RecognizedList.vue')
  },
  {
    path: '/:lang(en|cn)?/recognized-list',
    name: 'RecognizedListLang',
    component: () => import('../views/RecognizedList.vue')
  },

  {
    path: generatePath('/recognizedList-page'),
    name: 'RecognizedListPage',
    component: () => import('../views/RecognizedListPage.vue')
  },
  {
    path: generatePath('/best-practice-showcase'),
    name: 'BestPracticeShowcase',
    component: () => import('../views/PracticeShowcase.vue')
  },
  {
    path: generatePath('/profitional-body'),
    name: 'ProfitionalBody',
    component: () => import('../views/ProfitionalBody.vue')
  },
  {
    path: generatePath('/CO-FIT'),
    name: 'CoFit',
    component: () => import('../views/PracticeShowcase.vue')
  },
  {
    path: generatePath('/CO-FIT-2023'),
    name: 'CoFit-2023',
    component: () => import('../views/PracticeShowcase2023.vue')
  },
  {
    path: generatePath('/recognized-list-second-session'),
    name: 'recognizedListSecondSession',
    component: () => import('../views/recognizedListSecondSession.vue')
  },
  {
    path: generatePath('/judging-criteria'),
    name: 'JudgingCriteria',
    component: () => import('../views/JudgingCriteria.vue')
  },
  {
    path: generatePath('/awardees'),
    name: 'Awardees',
    component: () => import('../views/Awardees.vue')
  },
  {
    path: generatePath('/judge-2021-23'),
    name: 'JudgesLang',
    component: () => import('../views/Judges.vue')
  },
  {
    path: generatePath('/judge-2023-25'),
    name: 'Judges2325',
    component: () => import('../views/Judge2023.vue')
  },
  {
    path: generatePath('/ceremony'),
    name: 'Ceremony',
    component: () => import('../views/Ceremony')
  },
  {
    path: generatePath('/seminar'),
    name: 'Seminar',
    component: () => import('../views/Ceremony')
  },
  {
    path: generatePath('/kickoff'),
    name: 'Kickoff',
    component: () => import('../views/Ceremony')
  },
  {
    path: generatePath('/start'),
    name: 'Start',
    component: () => import('../views/Start.vue')
  },
  {
    path: generatePath('/highlights'),
    name: 'Corporate',
    component: () => import('../views/EventAndSeminar.vue')
  },
  {
    path: generatePath('/features'),
    name: 'Features',
    component: () => import('../views/Features.vue')
  },
  {
    path: generatePath('/sponsors'),
    name: 'Sponsors',
    component: () => import('../views/Sponsors')
  }
]

var lang = window.localStorage.getItem('locale')

const router = new VueRouter({
  mode: 'history',
  base: '/' + lang,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
