<template>
  <div :class="styleLang + ' app'">
    <el-backtop target=".app">
      <div name="el-fade-in" class="back-top"></div>
    </el-backtop>
    <myHeader />
    <div id="main">
      <router-view />
    </div>
    <myFooter />
  </div>
</template>

<script>
import Vue from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    myHeader: Header,
    myFooter: Footer
  },
  data() {
    return {
      styleLang: ''
    }
  },
  watch: {
    $route(to, from) {
      if (this.shouldUpdateLang()) {
        console.log('watch')
        this.updateLanguage()
      }
    }
  },
  created() {},
  methods: {
    shouldUpdateLang() {
      var url = window.location.href
      return url.includes('en') || url.includes('cn')
    },
    updateLanguage() {
      var url = window.location.href
      let lang = url.includes('en') ? 'en' : 'cn'
      let activeLang = lang === 'en' ? '1' : '0'
      // Update the local storage and component data
      window.localStorage.setItem('isActive', activeLang)
      window.localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      this.styleLang = window.localStorage.getItem('locale') || lang
    }
  }
}
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
.app {
  // Avenir, Helvetica, Arial, sans-serif,
  // font-family: PingFang;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  max-width: 1600px;
  margin: 0px auto;
  // //overflow-y: scroll;
  overflow-x: hidden;
  //height: 100%;
  // margin-right: 0px;
}
#main {
  min-height: 600px;
  //padding-bottom:100px;
}
html,
body {
  height: 100%;
}
.back-top {
  height: 80px;
  width: 50px;
  background: url('./assets/images/Group 11852.svg') no-repeat 100%/100%;
  z-index: 100;
  cursor: pointer;
  position: fixed;
  bottom: 8%;
  right: 5%;
  // opacity: 1;
  // animation: fadein .4s linear forwards;
}
// @keyframes fadein {
//   0% { opacity: 0; }
//   25% { opacity: .2; }
//   50% { opacity: .5; }
//   75% { opacity: .7; }
//   100% { opacity: 1; }
// }
// @keyframes fadeout {
//   0% { opacity: 1; }
//   25% { opacity: .7; }
//   50% { opacity: .5; }
//   75% { opacity: .2; }
//   100% { opacity: 0; }
// }
p {
  color: #707070;
  font-size: 15px;
  font-family: 'PingFang-Medium';
}
.el-backtop {
  border: none;
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
</style>
