<template>
  <div class="home-bg">
    <div class="video-container">
      <video class="video" autoplay muted loop>
        <source src="../assets/videos/Enterprises-move-together.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="box" @mouseout="out" @mouseover="over">
      <div v-for="(item, index) in list" v-show="listIndex === index" :key="index">
        <a v-if="index === 1" :href="$t('header.banner_nav')">
          <img :src="item" alt="" />
        </a>
        <a v-else href="#">
          <img :src="item" alt="" />
        </a>
      </div>
      <!-- <p class="left" @click="changePage(prevIndex)">&lt;</p> -->
      <img :src="require('../assets/images/white-left.png')" class="left" @click="changePage(prevIndex)">
      <li style="color:white" :class="{}" v-for="(item, index) in list" @click="changePage(index)" :key="index"></li>
      </ul>
      <!-- <p class="right" @click="changePage(nextIndex)">&gt;</p> -->
      <img :src="require('../assets/images/youjiantou-3.png')" class="right" @click="changePage(nextIndex)">
    </div>

    <div class="scheme-container">
      <h2>{{ $t("Home.scheme") }}</h2>
      <p>{{ $t("Home.scheme_desc") }}</p>
      <div class="buttonContainer">
         <!-- <p class="download">
          <span>{{ $t("Home.download_desc") }}</span>
          <span class="download_modle" @click="handleClickDownload()">{{ $t("Home.download_desc1") }}</span>
          <span>{{ $t("Home.download_desc2") }}</span>
        </p> -->
        <!-- <button class="button__scheme" @click="handleClick('signup')">
          {{ $t("Home.btn_1") }}
        </button> -->
        <button class="button__scheme" @click="handleClick('download')">
          {{ $t("Home.btn_2") }}
        </button>
        <button class="button__scheme" @click="handleClick('events')">
          {{ $t("Home.btn_3") }}
        </button>
        <!-- <p class="download">
          <span>{{ $t("Home.download_desc") }}</span>
          <span class="download_modle" @click="handleClickDownload()">{{ $t("Home.download_desc1") }}</span>
          <span>{{ $t("Home.download_desc2") }}</span>
        </p> -->
      </div>

    </div>
    <div class="timeline-container">
      <h2>{{ $t("Home.date_tit") }}</h2>
      <table>
        <tr>
          <td>{{ $t("Home.table.date_1") }}</td>
          <td>{{ $t("Home.table.desc_1") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_2") }}</td>
          <td>{{ $t("Home.table.desc_2") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_3") }}</td>
          <td>{{ $t("Home.table.desc_3") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_4") }}</td>
          <td>{{ $t("Home.table.desc_4") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_5") }}</td>
          <td>{{ $t("Home.table.desc_5") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_6") }}</td>
          <td>{{ $t("Home.table.desc_6") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_7") }}</td>
          <td>{{ $t("Home.table.desc_7") }}</td>
        </tr>
        <tr>
          <td>{{ $t("Home.table.date_8") }}</td>
          <td>{{ $t("Home.table.desc_8") }}</td>
        </tr>
      </table>
    </div>
    <div class="objective-container">
      <h2>{{ $t("Home.objective") }}</h2>
      <div class="objective__card-container">
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/division.png" alt="" />
            <h3>{{ $t("Home.objective_txt.title_1") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.objective_txt.description_1") }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/organization-chart.png" alt="" />
            <h3>{{ $t("Home.objective_txt.title_2") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.objective_txt.description_2") }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/online-community.png" alt="" />
            <h3>{{ $t("Home.objective_txt.title_3") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.objective_txt.description_3") }}</p>
          </div>
        </div>
      </div>
      <div class="objective__end">
        <img src="../assets/images/skyline.png" alt="" />
        <p>{{ $t("Home.objective_txt.endObjective") }}</p>
      </div>
    </div>
    <div class="countup" @mouseover="handleScroll" @mouseleave="handleLeave">
      <h1 id="countup"><span v-if="clickme">This is a secret message.</span></h1>
      <p style="line-height: 2">{{ $t("Home.achievment") }}</p>
    </div>
    <div class="whyContainer">
      <h2>{{ $t("Home.why.heading") }}</h2>
      <div class="objective__card-container">
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/team.png" alt="" />
            <h3>{{ $t("Home.why.subheading_1") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.why.text_1") }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/brand.png" alt="" />
            <h3>{{ $t("Home.why.subheading_2") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.why.text_2") }}</p>
          </div>
        </div>
        <div class="card">
          <div class="card__header">
            <img src="../assets/images/networking.png" alt="" />
            <h3>{{ $t("Home.why.subheading_3") }}</h3>
          </div>
          <div class="card__body">
            <p>{{ $t("Home.why.text_3") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="eligibilityContainer">
      <h2>{{ $t("Home.eligibility.heading") }}</h2>
      <div class="el_container">
        <div class="el_item">
          <img src="../assets/images/sport_hour_logo.svg" style="height: 100px; width: 100px" alt="" />
          <p>{{ $t("Home.eligibility.el_text_1") }}</p>
        </div>
        <div class="el_item">
          <img src="../assets/images/sport_logo_icon.svg" style="height: 100px; width: 100px" alt="" />
          <p>{{ $t("Home.eligibility.el_text_2") }}</p>
        </div>
      </div>
    </div>
    <!-- <p class="tip">
      {{ $t('Home.finish') }}
      <a href="https://sportshourcompany.inspiringhk.org/#/highlights">{{
        $t('Home.finish1')
      }}</a>
      {{ $t('Home.finish2') }}
    </p> -->
    <!-- <div class="apply">
      <a
        class="apply-btn"
        target="_blank"
        href="/pdf/SHC result annoucement 2021 (final).pdf"
      >
        <span class="apply-txt">{{ $t('Home.result') }}</span>
      </a>
    </div> -->
    <!-- <div class="apply apply1">
      <a
        class="apply-btn apply-bottom-btn"
        target="_blank"
        href="/pdf/Brochure_S2.pdf"
      >
        <span class="apply-txt">{{ $t('Home.apply_bottom_btn') }}</span>
      </a>
    </div>
    <p class="txt1" v-html="$t('Home.txt1')"></p>
    <p class="txt2" v-html="$t('Home.txt2')"></p>
    <p class="line"></p> -->
    <!-- <div class="video">{{ $t("header.coming") }}</div> -->
    <!-- <iframe
      class="video"
      src="https://www.youtube.com/embed/rHAs-gcyS0Y"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe> -->
    <!-- <br /> -->
    <!-- <p class="icon"></p>
    <p class="date-tit">{{ $t('Home.title') }}</p>
    <p class="txt2">{{ $t('Home.tip') }}</p>
    <p class="line"></p>
    <p class="icon"></p> -->
    <!-- <div class="date-con" :style="{ fontSize: lang === 'en' ? '12px' : '' }">
      <div class="date1">{{ $t('Home.date1') }}</div>
      <div class="date1-txt">
        <p style="width: 80%">{{ $t('Home.date1_txt') }}</p>
      </div>
      <div class="date2">{{ $t('Home.date2') }}</div>
      <div class="date2-txt">
        <p style="width: 50%">{{ $t('Home.date2_txt') }}</p>
      </div>
      <div class="date3">{{ $t('Home.date3') }}</div>
      <div class="date3-txt">
        <p style="width: 60%">{{ $t('Home.date3_txt') }}</p>
      </div>
      <div class="date4">{{ $t('Home.date4') }}</div>
      <div class="date4-txt">
        <p style="width: 80%">{{ $t('Home.date4_txt') }}</p>
      </div>
    </div> -->
    <div class="h4-container">
      <h4>{{ $t('Home.eligibility.des') }}</h4>
      <h4>{{ $t('Home.eligibility.des1') }}</h4>
    </div>
    <div class="logo-bottom-container">
      <div class="buttonContainer">
        <a target="_blank" href="https://drive.google.com/drive/folders/1RI8lKUDvMkGWVLarxtkufkTss1ozlXwR" download="SportsHour-Company.zip">
          <button class="button__scheme2">
            {{ $t('Home.eligibility.btn1') }}
          </button>
        </a>
        <a target="_blank" href="https://drive.google.com/drive/folders/1FkT_H_vPKpPNxCJyG__u8RxLVGRg1kkV" download="SportsHour-Organisation.zip">
          <button class="button__scheme">
            {{ $t('Home.eligibility.btn2') }}
          </button>
        </a>
      </div>
    </div>
    <!--<div class="seminar-poster">
      <div class="col-md-12">
          <div class="text-center">
            <img :src="$i18n.locale === 'cn' ? require('../../public/seminar.jpg') : require('../../public/seminar.jpg')" style="max-width: 85%; height: auto;" />
          </div>
        </div>
    </div>
    <div class="seminar-button">
      <a href="https://alt.jotfor.ms/ihksports/-shc-sharing">
        <button class="button-sign-up">
          {{ $t('Home.seminar.signup') }}
        </button>
      </a>
    </div>-->
    <!--<div id="cover">
      <img src="../../public/photo1.jpg" />
      <img src="../../public/photo2.jpg" />
      <img src="../../public/photo3.jpg" />
      <img src="../../public/photo4.jpg" />
    </div>
    <div class="h4-container">
      <h4>{{ $t('Home.kick_off.desc') }}</h4>
    </div>-->
    
    <!-- <div class="container"> 
      <div class="foot-icon">
        <div class="foot-partner">
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer2") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-support" src="../assets/images/footer/ctfe_logo.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-support" src="../assets/images/footer/ctfe_sol.png" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
        <div class="foot-partner" style="margin-left:25px;">
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer7") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/acoo.png" alt="" /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer3") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/meta.png" alt="" /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer5") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/linkedin.jpg" alt="" /></span>
              </div>
            </div>
          </div>
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer6") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/hoytv_red.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/i_cable.png" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
        <div class="foot-partner" style="margin-left:10px;">
          <div class="row">
            <p style="text-align: left">{{ $t("Footer.footer4") }}</p>
            <div class="foot-container">
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/dh.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/ef.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/hkca.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/hkgcc.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/hrm.webp" alt="" /></span>
              </div>
              <div class="foot-img-container">

                <span><img class="img-foot" :src="require(language === 'en' ? '../assets/images/footer/HKSTP_English.png' : '../assets/images/footer/HKSTP_Chinese.png')" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/JobsDB.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/kos_2.png" alt="" /></span>
              </div>
              <div class="foot-img-container">
                <span><img class="img-foot" src="../assets/images/footer/theDesk.png" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="img-list">
      <div class="img1" src="../assets/images/sport_hour_logo.svg"></div>
      <div class="img2"></div>
      <div class="img3"></div>
      <div class="img4"></div>
    </div> -->
    <div class="footer-img">
      <img class="foot"
        :src="require(language === 'en' ? '../assets/images/footer/Website logo (Eng)-01.jpg' : '../assets/images/footer/Website logo bar (Chi)-01.jpg')"
        alt="" />
    </div>
    <div>
      <p> <br> </p>
      <p> <br> </p>
    </div>
    <div class="sticky-footer">
      <div class="footer-btn-container">
        <!-- <button @click="handleClick('signup')">
          {{ $t("Home.footer.signup") }}
        </button> -->
        <button @click="handleClick('download')">
          {{ $t("Home.footer.download") }}
        </button>
      </div>
    </div>
  <!-- </div> -->
</div></template>
<script>
import { CountUp } from "../../node_modules/countup.js/dist/countUp";
export default {
  name: "Home",
  data() {
    return {
      lang: "",
      countup: "",
      viewCount: false,
      inViewPort: false,
      mousePosition: "",
      frameVal: 0,
      clickme: false,
      list: [
        require("../assets/images/new_banner.jpg"),
        require("../assets/images/banner/10000_steps.png"),
      ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
      language: window.localStorage.getItem('locale') || "cn",
      windowWidth: document.documentElement.clientWidth,
    }
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
    footerHeight() {
      return `${this.windowWidth * 0.85 / 7855 * 2835}px`
    }
  },
  methods: {
    download() {
      let a = document.createElement('a');
      a.href = '/pdf/SportsHour Company Brochure.pdf';
      a.download = 'SportsHour Company Brochure.pdf';
      a.click();
    },

    handleClick(value) {
      switch (value) {
        case "signup":
          // window.open("https://form.jotform.me/230161430862447", "_blank");
          window.open("https://docs.google.com/forms/d/e/1FAIpQLSePml-U15wjaq73fkn6b1_-6jptfbuLBaWvw9_txt7qV4TqmQ/viewform ", "_blank");
          break;
        case "download":
          this.download();
          // console.log(this.language);
          break;
        case "events":
          // //window.scrollTo(0, 0);
          this.$router.push('/ceremony');
          break;
        default:
          break;
      }
    },
    handleClickDownload() {
      let a = document.createElement('a');
      a.href = '/pdf/SHC application form.pdf';
      a.download = 'SHC application form.pdf';
      a.click();
    },
    handleScroll() {
      if (this.frameVal == 0) {
        const count = new CountUp("countup", 99, {
          enableScrollSpy: true,
          scrollSpyOnce: true,
          once: true
        });
        this.clickme = true
      }
    },
    handleLeave() {
      const count = new CountUp("countup", 99, {
        startVal: 99
      });
      this.frameVal = 99;
      console.log(this.frameVal)
      this.clickme = false
    },
    pickTop(e) {
      let scrollTop = e.target.scrollTop
      if (scrollTop > 2600) {
        this.handleScroll();
        window.removeEventListener("scroll", this.pickTop, true);

      }
    },
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 5000);
    },
    created() {
      //定时器
      this.setTimer();
    },
    mounted() {
      window.addEventListener('scroll', this.pickTop, true);
      const count = new CountUp("countup", 0, {
        enableScrollSpy: true,
        scrollSpyOnce: true,
        once: true
      });
      this.lang = localStorage.getItem("locale");
    },
    beforeDestroy() {

      window.removeEventListener("scroll", this.pickTop, true);
    },
  }
};
</script>

<style scoped lang="scss">
* {
  font-family: "PingFang-Medium", sans-serif;
}

@import "../style/enStyle.scss";
$panton-color_1: #1900ff;

.row-title {
    margin-top: 2rem;       
}

.text-center img {
    margin-bottom: 2rem;
}

.button-sign-up {
  cursor: pointer;
  padding: 35px 30px;
  width: 450px;
  border-radius: 4px;
  font-size: 22px;
  background-color: $panton-color_1;
  border: 1px solid $panton-color_1;
  color: #fff;
  transition: 0.3s;
  margin-bottom: 3rem;
}

.button-sign-up:hover {
  background-color: white;
  border: 1px solid $panton-color_1;
  color: $panton-color_1;
}

#cover {
  width: 87%;
  height: auto;
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3em;
}

#cover img {
  flex: 0 0 50%;
  padding: 10px;
  object-fit: cover;
  width: 50%;
  
}

.home-bg {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: #707070;
    margin-bottom: 1em;
    font-size: 2.9em;
    font-family: "Lato", sans-serif;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    z-index: -999;

    .video {
      z-index: -1;
      border-radius: 0;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  .download_modle {
    color: #4169E1;
    text-decoration: underline;
  }

  .scheme-container {
    margin-top: 1em;
    width: 70%;
    display: grid;

    p {
      font-size: 14px;
      line-height: 28px;
    }

    .download {
      width: 250px;
      text-align: left;
      line-height: 18px;
    }

    .buttonContainer {
      margin-top: 2em;
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 1em;
      grid-template-columns: 1fr 1fr 1fr;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        grid-template-columns: 1fr;
      }

      .button__scheme {
        cursor: pointer;
        padding: 15px 10px;
        width: 250px;
        border-radius: 4px;
        font-size: 16px;
        background-color: $panton-color_1;
        border: 1px solid $panton-color_1;
        color: #fff;
        transition: 0.3s;

        &:hover {
          background-color: white;
          border: 1px solid $panton-color_1;
          color: $panton-color_1;
        }
      }
    }
  }

  .footer-img {
    width: 85%;
    height: footerHeight;

    .foot {
      width: 100%;

    }
  }



  .timeline-container {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
      border-collapse: collapse;
      width: 155%;

      @media screen and (max-width: 669px) {
        width: 90%;
      }

      td,
      th {
        border: 1px solid black;
        text-align: left;
        padding: 12px;
        text-align: center;
      }

      tr {
        transition: 0.3s;

        &:hover {
          background-color: #d2d2d2;
          cursor: pointer;
        }
      }
    }
  }

  .objective__card-container {
    display: flex;
    gap: 20px;

    .card {
      background-color: transparent;
      padding: 20px;
      border-radius: 30px;
      border: 5px solid $panton-color_1;
      transition: 0.4s;
      max-width: 300px;

      .card__header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        img {
          width: 50px;
          height: 50px;
          transition: 0.4s;
        }
      }

      .card__body {
        padding-top: 10px;

        p {
          font-size: 14px;
        }
      }

      &:hover {
        transform: scale(1.1);

        img {
          rotate: y 180deg;
        }
      }
    }

    @media screen and (max-width: 720px) {
      flex-direction: column;
    }
  }

  .objective-container {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .objective__end {
      position: relative;
      top: 2em;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 2em;
      padding: 40px;
      background-color: $panton-color_1;
      border-radius: 60px;
      transition: 0.5s;
      margin-bottom: 2em;

      p {
        color: white;
        font-size: 22px;
        vertical-align: middle;
        margin-top: 14px;
      }

      img {
        height: 50px;
        widows: 50px;
        transition: 0.5s;
      }

      &:hover {
        img {
          rotate: x 360deg;
        }
      }
    }

    @media screen and (max-width: 720px) {
      width: 90%;
    }
  }

  .countup {
    margin-top: 1em;
    font-size: 5em;

    p {
      font-size: 16px;
      max-width: 100ch;
    }

    h1 {
      font-size: 6rem;
    }

    @media screen and (max-width: 700px) {
      width: 90%;
    }
  }

  .whyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }

  .eligibilityContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block: 2em 5em;

    .el_container {
      display: flex;
      align-items: top;
      justify-content: center;
      gap: 2em;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      .el_item {
        width: 300px;
        border: 1px solid black;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 2px 5px 0px 1px #000;
        transition: 0.2s ease-in-out;

        img {
          width: 30px;
          height: 30px;
          margin-bottom: 1em;
        }

        p {
          padding-top: 5px;
          border-top: 2px solid grey;
          text-align: left;
          font-size: 16px;
          line-height: 2;
        }

        &:hover {
          transform: translate(4px, 4px);
          box-shadow: none;
        }
      }
    }
  }

  .h4-container {
    width: 70%;
    margin-bottom: 3rem;
  }

  .logo-bottom-container {

    width: 70%;
    margin-bottom: 3em;
    margin-top: 20px;

    p {
      font-size: 14px;
      line-height: 28px;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1em;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      .button__scheme {
        cursor: pointer;
        padding: 15px 10px;
        width: 250px;
        border-radius: 4px;
        font-size: 16px;
        background-color: #1900ff;
        border: 1px solid #1900ff;
        color: #fff;
        transition: 0.3s;

        &:hover {
          background-color: #fff;
          border: 1px solid #1900ff;
          color: #1900ff;
        }
      }

      .button__scheme2 {
        cursor: pointer;
        padding: 15px 10px;
        width: 250px;
        border-radius: 4px;
        font-size: 16px;
        background-color: #1900ff;
        border: 1px solid #1900ff;
        color: #fff;
        transition: 0.3s;

        &:hover {
          background-color: #fff;
          border: 1px solid #1900ff;
          color: #1900ff;
        }
      }
    }
  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 20px;

    .footer-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      button {
        border: 2px solid yellow;
        border-radius: 5px;
        padding: 5px;
        width: 250px;
        height: 70px;
        background-color: transparent;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        color: white;
      }
    }
  }


  .icon {
    width: 40px;
    height: 40px;
    margin: 40px auto 5px;
    background: url("../assets/images/Group 11846.svg") 50%/50% no-repeat;
  }

  .box {
    position: relative;
    width: 70%;

    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }

    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: transparent;
    }

    .left {
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 30px;
    }

    .right {
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 30px;
    }

    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: absolute;
      width: 150px;
      height: 20px;
      top: 90%;
      right: 45%;

      .color {
        background: red;
        color: red;
      }

      li {
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .img-tit {
    margin: 0px auto;
    margin-top: 20px;
    width: 70%;
    height: 420px;
    background: url("../assets/images/new_banner.jpg") no-repeat 100%/100%;
  }

  .tip {
    margin-top: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
    width: 60%;
    margin-left: 20%;
  }

  .apply {
    height: 180px;
    // background: red;
    margin: 50px auto 0px;
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .borbo {
      position: absolute;
      left: 50%;
      bottom: 25px;
      transform: translate(-50%, -50%);
      width: 200px;
      height: 5px;
      background: #6ec9f5;
      border-radius: 10px;
    }

    .apply-btn,
    .apply-date {
      position: absolute;
      width: 465px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }

    .apply-btn {
      top: 25px;
      left: 30px;
      z-index: 2;
      background: url("../assets/images/Mask Group 623.svg") no-repeat 100%/100%;

      .apply-txt {
        font-size: 28px;
        letter-spacing: 1px;
        font-style: italic;
        // padding: 0 65px;
        color: #fff;
        text-shadow: #6ec9f5 3px 1px 1px;
        font-family: "PingFang-bold";
      }
    }

    .apply-date {
      top: 50px;
      left: 335px;
      background: url("../assets/images/Path 10047.svg") no-repeat 100%/100%;
      // font-size: 12px;
    }
  }

  .apply1 {
    margin: 0px auto;

    .apply-bottom-btn {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .txt1,
  .txt2 {
    width: 55%;
    margin: 40px auto;
    line-height: 28px;
    // font-family: 'PingFang-Regular';
  }

  .line {
    width: 65%;
    background-color: #d2d2d2;
    height: 1px;
    margin: 60px auto;
  }

  .date-tit {
    font-size: 24px;
    color: #1e30f6;
    font-family: "PingFang-Heavy";
  }

  .date-con {
    width: 70%;
    margin: 0px auto;
    // background: red;
    height: 280px;
    position: relative;
    letter-spacing: 2px;
    // font-size: 12px;
    font-family: "PingFang-Heavy";

    .date1-txt,
    .date3-txt {
      position: absolute;
      width: 300px;
      height: 100px;
      /*flex 布局*/
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      /*实现水平居中*/
      justify-content: center;
      color: #fff;
      background: url("../assets/images/Path 11483.svg") no-repeat 100%/100%;

      p {
        color: #fff;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .date2-txt,
    .date4-txt {
      position: absolute;
      width: 300px;
      height: 100px;
      /*flex 布局*/
      display: flex;
      /*实现垂直居中*/
      align-items: center;
      /*实现水平居中*/
      justify-content: center;
      color: #fff;
      background: url("../assets/images/Path 11482.svg") no-repeat 100%/100%;

      p {
        font-size: 20px;
        color: #fff;
        line-height: 20px;
      }
    }

    .date1-txt {
      top: 60px;
      left: 10px;
    }

    .date2-txt {
      top: 90px;
      left: 225px;
    }

    .date3-txt {
      top: 60px;
      left: 440px;
    }

    .date4-txt {
      top: 90px;
      left: 660px;
    }

    .date1,
    .date3 {
      position: absolute;
      color: #6ec9f5;
    }

    .date2,
    .date4 {
      position: absolute;
      color: #1e30f6;
    }

    .date1 {
      top: 45px;
      left: 125px;
    }

    .date2 {
      top: 185px;
      left: 290px;
    }

    .date3 {
      top: 45px;
      left: 555px;
    }

    .date4 {
      top: 185px;
      left: 750px;
    }
  }

  .img-list {
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // background: red;
    .img1,
    .img2,
    .img3,
    .img4 {
      width: 500px;
      height: 200px;
      margin-right: -50px;
    }

    // .img1 {
    //   margin-left: -80px;
    //   background: url('../assets/images/20200619-7.png') no-repeat 100%/100%;
    // }
    // .img2 {
    //   background: url('../assets/images/IMG_0478E.png') no-repeat 100%/100%;
    // }

    // .img3 {
    //   background: url('../assets/images/IMG_0477E.png') no-repeat 100%/100%;
    // }
    // .img4 {
    //   margin-right: -80px;
    //   background: url('../assets/images/FAN-32.png') no-repeat 100%/100%;
    // }
  }

  .video {
    width: 50%;
    height: 400px;
    margin: 0px auto;
    // background: red;
    border-radius: 20px;
    box-shadow: #efecef 2px 2px 8px 2px;
    line-height: 400px;
  }

  .foot-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: -75px;

    .foot-column {
      display: flex;
      flex-direction: column;
    }

    .foot-partner {
      display: flex;
      flex-direction: column;
      margin-left: 65px;

      @media screen and (max-width: 720px) {
        margin-left: 0;

        .row {
          justify-content: center;
        }
      }
    }

    .foot-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 400px;

      @media screen and (max-width: 720px) {
        justify-content: center;
        padding-right: 20px;
      }
    }

    .foot-img-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 30px;
      padding-right: 30px;
    }


    .img-foot {
      max-height: 60px;
      max-width: 140px;
      float: left;
    }

    .img-support {
      max-height: 60px;
      max-width: 150px;
      float: left;
    }

    .row-bot {
      margin-top: 20px;
      margin-left: 45px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
    }

    @media screen and (max-width: 720px) {
      flex-direction: column;
      margin-bottom: -75px;

      .img-foot {
        text-align: center;
        align-items: center;
        float: none;
      }

      p {
        text-align-last: center;
        margin-top: 20px;
      }

      .img-support {
        text-align: center;
        align-items: center;
        float: none;
        margin-left: 5px;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 520px) {
      .foot-icon {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin-bottom: -75px;

        .foot-column {
          display: flex;
          flex-direction: column;
        }

        .foot-partner {
          display: flex;
          flex-direction: column;
        }

        .foot-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }

        .foot-img-container {
          display: flex;
          flex-direction: row;
          padding-bottom: 30px;
          padding-right: 30px;
        }


        .img-foot {
          max-height: 60px;
          max-width: 140px;
          float: left;
        }

        .img-support {
          max-height: 60px;
          max-width: 150px;
          float: left;
        }

        .row-bot {
          margin-top: 20px;
          margin-left: 45px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-evenly;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .home-bg {
    .img-tit {
      width: 100%;
      height: 150px;
      background: url("../assets/images/Group 11844.svg") no-repeat 100%/105%;
    }

    .box {
      width: 100%;
      height: auto;
    }

    .tip {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      width: 80%;
      margin-left: 10%;
    }

    .apply {
      margin: 0px auto;
      width: 100%;
      height: 200px;
      text-align: center;

      .apply-btn {
        top: 5%;
        left: 0%;
        width: 100%;

        // margin: 0px 20px 0px 5px;
        .apply-txt {
          font-size: 22px;
        }
      }

      .apply-date {
        top: 45%;
        left: 0%;
        width: 100%;
        height: 120px;
        // padding: 0 5px 0 5px;
      }
    }

    .apply1 {
      .apply-bottom-btn {
        transform: translateX(0%);
        margin: 40px auto;
      }
    }

    .txt1,
    .txt2 {
      width: 90%;
    }

    .line {
      width: 90%;
    }

    .video {
      width: 90%;
    }

    .date-con {
      width: 80%;
      height: auto;
      margin: 8% auto;

      .date1-txt,
      .date2-txt,
      .date3-txt,
      .date4-txt,
      .date1,
      .date2,
      .date3,
      .date4 {
        width: 100%;
        position: static;
      }
    }

    .img-list {

      .img1,
      .img2,
      .img3,
      .img4 {
        height: 100px;
      }
    }
  }
}</style>
