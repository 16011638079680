<template>
  <div class="header_bg">
    <a class="logo" target="_self" href="http://inspiringhk.org/#/"></a>
    <!--<div class="big-logo"></div>
    <div class="big-logo"></div>-->
    <div class="logo-container">
      <a class="company" href=""></a>
      <a class="organization" href=""></a>
      <i @click="(drawer = true), (menuShow = false)" class="el-icon-menu"></i>
    </div>

    <div class="right">
      <div class="social-icon">
        <a
          class="head-icon"
          target="_blank"
          href="https://www.facebook.com/ihksports/"
        ><img src="@/assets/images/Path 10.svg" alt=""
        />
        </a>
        <a
          class="head-icon"
          target="_blank"
          href="https://www.instagram.com/accounts/login/?next=/ihksports/"
        >
          <img src="@/assets/images/Group 19-2.svg" alt="" />
        </a>
        <a
          class="head-icon"
          target="_blank"
          href="https://www.linkedin.com/company/ihksports/"
          ><img src="@/assets/images/linkedin-svg.svg" alt=""
        /></a>
        <a
          class="head-icon"
          target="_blank"
          href="mailto:sportshourcompany@inspiringhk.org"
          ><img src="@/assets/images/mail.svg" alt=""
        /></a>
      </div>
      <div class="lang">
        <p
        :class="[isShowLangActive == 0 ? 'lang-active' : '', 'lang']"
        @click="changLang('cn')"
        >
          中文
        </p>
        <p
          :class="[isShowLangActive == 1 ? 'lang-active' : '', 'lang']"
          @click="changLang('en')"
        >
          ENG
        </p>
      </div>
      <!-- <a
          class="head-icon"
          target="_blank"
          href="https://www.facebook.com/ihksports/"
      ><img src="@/assets/images/Path 10.svg" alt=""
      />
      </a>
      <a
        class="head-icon"
        target="_blank"
        href="https://www.instagram.com/accounts/login/?next=/ihksports/"
      >
        <img src="@/assets/images/Group 19-2.svg" alt="" />
      </a>
      <a
        class="head-icon"
        target="_blank"
        href="https://www.linkedin.com/company/ihksports/"
        ><img src="@/assets/images/linkedin.svg" alt=""
      /></a>
      <a
        class="head-icon"
        target="_blank"
        href="mailto:sportshourcompany@inspiringhk.org"
        ><img src="@/assets/images/mail.svg" alt=""
      /></a> -->
      <!-- <p
        :class="[isShowLangActive == 0 ? 'lang-active' : '', 'lang']"
        @click="changLang('cn')"
      >
        中文
      </p>
      <p
        :class="[isShowLangActive == 1 ? 'lang-active' : '', 'lang']"
        @click="changLang('en')"
      >
        ENG
      </p> -->
    </div>

    <!-- <div class="nav-list">
      <p
        @mouseenter="menushow(item.page)"
        v-for="(item, index) in navList"
        :key="index"
        @click="goPush(item.page, index)"
        :class="[index == current ? 'changNavActive' : '', 'nav']"
      >
        <span>{{ $t("header.nav" + (index + 1)) }}</span>
      </p>
      <span
        class="menu munu-pc"
        v-show="menuShowRecognized"
        @mouseleave="menuclose()"
      >
        <ul style="list-style-type: none">
          <li @click="menuShowRecognized = !menuShowRecognized">
            <router-link class="link" to="/recognized-list-second-session">{{
              $t(`MenuItems.nav1`)
            }}</router-link>
          </li>
          <li @click="menuShowRecognized = !menuShowRecognized">
            <router-link class="link" to="/recognizedList-page">{{
              $t(`MenuItems.nav2`)
            }}</router-link>
          </li>
        </ul>
      </span>
      <span
        class="menu munu-pc"
        v-show="menuShowBestPractice"
        @mouseleave="menuclose()"
      >
        <ul style="list-style-type: none">
          <li @click="menuShowBestPractice = !menuShowBestPractice">
            <router-link class="link" to="/profitional-body">{{
              $t(`MenuItems.nav3`)
            }}</router-link>
          </li>
          <li @click="menuShowBestPractice = !menuShowBestPractice">
            <router-link class="link" to="/CO-FIT">{{
              $t(`MenuItems.nav4`)
            }}</router-link>
          </li>
        </ul>
      </span>
      <span
        class="menu munu-pc judge"
        v-show="menuShowJudge"
        @mouseleave="menuclose()"
      >
        <ul style="list-style-type: none">
          <li @click="menuShowJudge = !menuShowJudge">
            <router-link class="link" to="/judge-2023-25">{{
              $t(`MenuItems.nav1`)
            }}</router-link>
          </li>
          <li @click="menuShowJudge = !menuShowJudge">
            <router-link class="link" to="/judge-2021-23">{{
              $t(`MenuItems.nav2`)
            }}</router-link>
          </li>
        </ul>
      </span>
    </div> -->
    <div class="nav-list">
      <div v-for="(item, index) in navList" :key="index" class="dropdown">
        <button
          @click="goPush(item.page, index)"
          @mouseenter="menushow(item.page)"
          :class="[index == current ? 'changNavActive' : '', 'nav']"
          class="dropbtn"
        >
          {{ $t("header.nav" + (index + 1)) }}
        </button>
        <div v-show="menuShowRecognized" class="dropdown-content">
          <a href="">
            <router-link class="link" to="/recognized-list-second-session">{{
              $t(`MenuItems.nav1`)
            }}</router-link>
          </a>
          <a href="">
            <router-link class="link" to="/recognizedList-page">{{
              $t(`MenuItems.nav2`)
            }}</router-link>
          </a>
        </div>
        <div v-show="menuShowBestPractice" class="dropdown-content">
          <a href="">
            <router-link class="link" to="/CO-FIT-2023">{{
              $t(`MenuItems.nav8`)
            }}</router-link>
          </a>
          <a href="">
            <router-link class="link" to="/CO-FIT">{{
              $t(`MenuItems.nav2`)
            }}</router-link>
          </a>
        </div>
        <div v-show="menuShowJudge" class="dropdown-content">
          <a href="">
            <router-link class="link" to="/judge-2023-25">{{
              $t(`MenuItems.nav1`)
            }}</router-link>
          </a> 
          <a href="">
            <router-link class="link" to="/judge-2021-23">{{
              $t(`MenuItems.nav2`)
            }}</router-link>
          </a>
        </div>
        <div v-show="menuShowEvent" class="dropdown-content">
          <a href="">
            <router-link class="link" to="/ceremony">{{
              $t(`MenuItems.nav5`)
            }}</router-link>
          </a>
          <a href="">
            <router-link class="link" to="/seminar">{{
              $t(`MenuItems.nav6`)
            }}</router-link>
          </a>
          <a href="">
            <router-link class="link" to="/kickoff">{{
              $t(`MenuItems.nav7`)
            }}</router-link>
          </a>
        </div>
      </div>
    </div>
    <div class="mobile" @touchmove.prevent>
      <el-drawer
        @click="menuclose"
        size="60%"
        :visible.sync="drawer"
        :direction="direction"
      >
        <div class="mobile-logo">
          <img width="50%" src="../assets/images/ihk-logo.png" alt="" />
        </div>
        <p
          v-for="(item, index) in navList"
          :key="index"
          :class="[
            index == current ? 'changNavActive' : '',
            'nav' + index,
            'mobilemenu',
          ]"
        >
          <span @click="goPush(item.page, index)">{{
            $t("header.nav" + (index + 1))
          }}</span>
          <span
            @click="menushow(item.page)"
            v-if="index == 2 || index === 3"
            class="el-icon-arrow-right"
          ></span>
        </p>
        <span
          class="mo-tab"
          v-show="menuShowRecognized"
          @mouseleave="menuclose()"
        >
          <ul style="list-style-type: none">
            <li
              @click="mobileShow"
              v-for="(item, index) in mobilelistRecognized"
              :key="index"
            >
              <router-link class="link" :to="item.page">{{
                $t(`MenuItems.nav` + (index + 1))
              }}</router-link>
            </li>
          </ul>
        </span>
        <span
          class="mo-tab"
          v-show="menuShowBestPractice"
          @mouseleave="menuclose()"
        >
          <ul style="list-style-type: none">
            <li
              @click="mobileShow"
              v-for="(item, index) in mobilelistBestPractice"
              :key="index"
            >
              <router-link class="link" :to="item.page">{{
                $t(`MenuItems.nav` + (index + 3))
              }}</router-link>
            </li>
          </ul>
        </span>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "myHeader",
  data() {
    return {
      // "/awardees",, "/ceremony"
      // activeNames: ['1'],
      drawer: false,
      direction: "ltr",
      menuShowRecognized: false,
      menuShowBestPractice: false,
      menuShowJudge: false,
      menuShowEvent: false,
      lang: window.localStorage.getItem('locale'),
      isShowLangActive: window.localStorage.getItem("isActive") || 0,
      mobilelistRecognized: [
        { page: "/recognized-list-second-session" },
        { page: "/recognizedList-page" },
      ],
      mobilelistBestPractice: [
        { page: "/profitional-body" },
        { page: "/CO-FIT" },
        { page: "/CO-FIT-2023" },
      ],
      navList: [
        {
          page: "/",
        },
        // {
        //   page: "/about-us",
        // },
        {
          page: "/judging-criteria",
        },
        {
          page: "/recognizedList-page",
          menu: [
            {
              page: "/recognized-list-second-session",
            },
            {
              page: "/recognizedList-page",
            },
          ],
        },
        {
          page: "/best-practice-showcase",
          menu: [
            {
              page: "/profitional-body",
            },
            {
              page: "/CO-FIT",
            },
            {
              page: "/CO-FIT-2023",
            },
          ],
        },
        {
          page: "/judge-2023-25",
          menu: [
            {
              page: "/judge-2023-25",
            },
            {
              page: "/judge-2021-23",
            },
          ],
        },
        {
          page: "/ceremony",
        },
        {
          page: "/sponsors",
        },
        {
          page: "/start",
        },
        {
          page: "/features",
        },
      ],
      current: 0,
      screenwidth: document.body.clientWidth,
    };
  },
  methods: {
    mobileShow() {
      this.menuShow = !this.menuShow;
      this.drawer = false;
    },
    menushow(page) {
      if (page === "/recognizedList-page") {
        this.menuShowRecognized = true;
        this.menuShowBestPractice = false;
        this.menuShowJudge = false;
        this.menuShowEvent = false;
      } else if (page === "/best-practice-showcase") {
        this.menuShowRecognized = false;
        this.menuShowBestPractice = true;
        this.menuShowJudge = false;
        this.menuShowEvent = false;
      } else if (page === "/judge-2023-25") {
        this.menuShowRecognized = false;
        this.menuShowBestPractice = false;
        this.menuShowJudge = true;
        this.menuShowEvent = false;
      } else if (page == "/highlights"){
        this.menuShowRecognized = false;
        this.menuShowBestPractice = false;
        this.menuShowJudge = false;
        this.menuShowEvent = true;
      } else if (page === "/ceremony"){
        this.menuShowRecognized = false;
        this.menuShowBestPractice = false;
        this.menuShowJudge = false;
        this.menuShowEvent = true;
      } else{
        this.menuShowRecognized = false;
        this.menuShowBestPractice = false;
        this.menuShowJudge = false;
        this.menuShowEvent = false;
      }
    },
    menuclose() {
      this.menuShowRecognized = false;
      this.menuShowBestPractice = false;
      this.menuShowJudge = false;
    },
    changLang(lang) {
      window.localStorage.setItem("locale", lang);
      if(lang == "cn"){
        this.isShowLangActive = 0
        window.localStorage.setItem("isActive", 0)
      }else{
        this.isShowLangActive = 1
        window.localStorage.setItem("isActive", 1)
      }
      // 修改显示语言
      this.$i18n.locale = lang;
      var url = window.location.href;

      if(url.search('/cn/') > -1){
        var lan = '/'+lang+'/';
        url = url.replace('/cn/', lan);
      }else if(url.search('/en/') > -1){
        url = url.replace('/en/', '/'+lang+'/');
      }
      location.href = url;
    },
    goPush(page, i) {
      let str = window.location.href;
      let index = str.lastIndexOf(this.lang+"/");
      if (page === str.substring(index + 1, str.length)) {
        return;
      }
      this.current = i;
      window.localStorage.setItem("isActive", 0);
      this.$router.push(page);
      gtag("event", "page_view", {
        page_location: "http://sportshourcompany.inspiringhk.org/#/",
        page_path: page,
        page_title: page,
      });
      this.menuShow = false;
      if (this.screenwidth >= 520 && page == "/recognized-list") {
        this.menuShow = !this.menuShow;
      }
      this.drawer = false;
      // console.log(this.screenwidth);
      // console.log(page);
    },
    //刷新保持tab
    unchangedTab() {
      let str = window.location.href;
      let index = str.lastIndexOf(this.lang+"/");
      let route = str.substring(index + 1, str.length);
      for (let i = 0; i < this.navList.length; i++) {
        if (route === this.navList[i].page) {
          return i;
        }
      }
    },
  },

  created() {
    // console.log(this.isShowLangActive);
    this.current = this.unchangedTab();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.menu {
  position: absolute;
  top: 63px;
  left: 20%;
  padding: 10px;
  font-size: 14px;
  // display: none;
  background-color: rgb(110, 201, 245);
  color: black !important;
  cursor: default !important;
  li {
    margin-bottom: 5px;
    cursor: pointer;
    .link {
      display: block;
      text-align: left;
      text-decoration: none;
      color: #222ef4 !important;
    }
  }
}

.menu.munu-pc.judge {
  left: 48%;
}

// .nav:hover {
//   .menu {
//     display: block;
//   }
// }
.header_bg {
  height: 200px;
  position: relative;
  border-bottom: 1px solid #d2d2d2;
  .big-logo {
    margin: 0px auto;
    width: 180px;
    height: 150px;
    background: url("../assets/images/Group 11866.svg") no-repeat 100%/100%;
  }
  .logo-container {
    margin: 0 auto;
    display: flex;
    height: 150px;
    max-width: 400px;
    .company {
      width: 180px;
      height: auto;
      background: url("../assets/images/Group 11866.svg") no-repeat 100%/100%;
    }
    .organization {
      width: 220px;
      max-height: 130px;
      height: auto;
      margin-top: 12px;
      background: url("../assets/images/sport_hour_new_logo.png") no-repeat 100%/100%;
    }
  }
  .logo {
    position: absolute;
    top: 2%;
    left: 3%;
    width: 200px;
    height: 150px;
    background: url("../assets/images/anniversary_logo.png") no-repeat 100%/100%;
  }
  .right {
    position: absolute;
    top: 8%;
    right: 2%;
    height: 50px;
    width: 20%;
    // background: blue;
    display: flex;
    flex-direction:row;
    justify-content: space-around;

    .social-icon{
      display: flex;
      align-items: center;
      
    }

    .lang {
      cursor: pointer;
      color: #5b626f;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-left:10px;
    }

    .head-icon {
      width: 35px;
      height: 35px;
      background: url("../assets/images/Rectangle 2482.svg") no-repeat 110%/100%;
      margin:2px;
      img {
        padding: 2px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav-list {
    :nth-child(3):hover {
      // background: rgb(170, 151, 151);
      //   .menu{
      //     display: block !important;
      //   }
    }
    padding-inline: 20px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // flex-wrap:wrap;
    color: #4b5c73;
    list-style: none;
    position: absolute;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    letter-spacing: 2px;
    .nav {
      // background: rgb(211, 209, 209);
      display: inline-block;
      color: #4b5c73;
      font-size: 13px;
      margin-left: 5px;
      text-align: center;
      font-family: "PingFang-Bold"; //PingFang-Heavy PingFang-Bold
      cursor: pointer;
      // background: red;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      // text-align: justify;
    }
  }
  .el-icon-menu {
    display: none;
  }
  .changNavActive {
    color: #1e30f6;
    border-bottom: 3px solid #222ef4;
  }
  .lang-active {
    color: #222ef4;
    border-bottom: 3px solid #222ef4;
  }
}

@media screen and (max-width: 520px) {
  .header_bg {
    height: 240px;

    .right {
      width: 56%;
    }
    .logo {
      width: 120px;
      height: 70px;
    }
    .big-logo {
      padding-top: 40%;
      width: 150px;
      height: 110px;
    }
    .logo-container {
      padding-top: 27%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      position: relative;
      height:250px;
      .company {
        width: 130px;
        height: auto;
        margin-top: -100px;
      }
      .organization {
        width: 170px;
        height: auto;
        margin-top: -40px;
      }
      .el-icon-menu {
        display: block !important;
        font-size: 30px;
        top: 88px;
        right: 10px;
        color: rgb(110, 201, 245);
      }
    }
    .nav-list {
      display: flex;
      flex-direction: column;
      width: 95%;
      letter-spacing: 0;
      display: none;
      position: relative;
    }
  }

  .changNavActive {
    margin: 0 3px;
    border-radius: 10px;
    color: #1e30f6;
    // border-bottom: none !important;
    background: #d8f7f7a6;
    border-bottom: 4px solid rgb(182, 220, 240) !important;
  }
  .mobile {
    // position: relative;
    display: block;
    .mobile-logo {
      // position: absolute;
      // top: 0;
      // left: -30px;
      margin-right: 30px;
    }
    .mobilemenu {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 15px 10px;
      font-size: 17px;
    }
    .nav2,
    .nav3 {
      display: flex;
      flex-direction: row;
      .el-icon-arrow-right {
        font-size: 20px;
        margin-top: 2px;
        margin-left: 15px;
      }
    }
    .el-drawer__wrapper {
      .el-drawer__container {
        position: relative;
        .el-drawer {
          .el-drawer__header {
            display: none;
          }
          .el-drawer__body {
            .mo-tab {
              ul {
                position: absolute;
                top: 145px;
                right: -160px;
                background: rgb(173, 240, 253);
                // height: 100px;
                margin-left: 180px;
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                li {
                  padding: 10px 15px;
                  text-align: left;
                  font-size: 15px;
                  font-family: "PingFang-Medium";
                  .link {
                    color: rgb(25, 0, 255);

                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .el-drawer.ltr {
      overflow: visible !important;
    }
  }
}
@media screen and (min-width: 520px) {
  .mobile {
    display: none;
  }
  .v-modal {
    display: none;
  }
}

.dropbtn {
  background-color: transparent;
  color: rgb(136, 109, 109);
  font-size: 13px;
  border: none;
  white-space: nowrap;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(110, 201, 245);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  font-size: small;
}

.dropdown-content a {
  color: rgb(99, 28, 231);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: rgb(110, 201, 245);
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
