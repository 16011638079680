import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n/i18n'
import VueI18n from 'vue-i18n'
import VueLazyload from "vue-lazyload";
import {
  Backtop,
} from 'element-ui'
import "./assets/font/font.css"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import "./style/enStyle.scss";

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(Backtop)
Vue.use(ElementUI);
Vue.use(VueLazyload, {
  preLoad:1.3,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
