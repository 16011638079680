import cnLocale from 'element-ui/lib/locale/lang/zh-CN'
const cn = {
  header: {
    nav1: '計劃簡介',
    nav2: '評審標準',
    nav3: '歷屆獲嘉許名單',
    nav4 :'優秀實踐案例',
    nav5: '專業評審團',
    nav6: '活動',
    nav7: '贊助及合作伙伴',
    nav8: '「一」起動！',
    nav9: '媒體宣傳',
    coming: '即將更新',
    nav6_desc: '即將公布，敬請期待！',
    banner_nav: 'https://www.change4health.gov.hk/tc/physical_activity/10000_steps_a_day/index2.html'
  },
  MenuItems: {
    nav1: '2023-25',
    nav2: '首届 2021-23',
    nav3 : '第二屆《「職」時心動》實踐案例',
    nav4 : '首屆《「疫」要「一」起動》實踐案例',
    nav5 : '年度典禮',
    nav6 : '活動',
    nav7 : '起動活動',
    nav8 : '2023-25',
    title1: '首屆《企業「一」起動》嘉許計劃獲嘉許名單',
    list_title1: '少於 50 名員工之企業',
  },
  Footer: {
    footer1: '主辦機構',
    footer2: '策略倡議夥伴',
    footer3: '數碼夥伴',
    footer4: '支持夥伴',
    footer5: '學習夥伴',
    footer6: '媒體夥伴',
    footer7: '創意夥伴'
  },
  Home: {
    apply_btn: '立即申請  !',
    finish: ' * 首屆已完滿結束，多謝參與！請',
    scheme: '計劃簡介',
    scheme_desc: '凝動香港體育基金於2021年創立及推出《企業「一」起動》嘉許計劃。計劃以企業及機構作對象，旨在鼓勵香港企業及機構建立充滿健康活力的工作環境，由僱主帶動員工及其家人每天共參與「一小時」體能活動，促進員工身心健康，同時提升工作士氣和效率。與此同時，計劃希望促進企業及機構以體育回饋社會，建立大眾健康生活意識，連結社會各界為全城身心健康出一分力。',
    download_desc: '如您未能於網上填寫報名表格，請',
    download_desc1 : '於此',
    download_desc2 : '下載.',
    btn_1 : '立即報名（網上版）',
    btn_2 : '計劃小冊子',
    btn_3 : '活動和研討會',
    objective : '計劃目的',
    finish1: '按此',
    finish2: '重溫',
    result: '首屆獲嘉許公司及機構名單',
    apply_bottom_btn: '下載計劃小冊子 ',
    apply_date: '*本年度截止日期為五月三十一日',
    txt1:
      '凝動香港體育基金於2021年創立及推出<strong>《企業「一」起動》嘉許計劃及年度優秀企業實踐案例</strong>，鼓勵香港企業及機構建立充滿健康活力的工作環境，由僱主帶動員工及其家人每天共參與「一小時」體能活動，推動員工的身心健康從而提升企業的效益。與此同時，計劃希望促進企業及機構以體育回饋社會，建立大眾健康生活意識，連結社會各界為全城身心健康出一分力。',
    txt2:
      "計劃首屆目標嘉許超過100間來自不同行業的企業及機構，鼓勵僱主及企業員工共同為香港社會建立活力企業圈。計劃同時舉辦年度優秀企業實踐案例，首屆以<strong style='color: #1e30f6;'>《「疫」要「一」起動》</strong>為主題，由十位來自不同界別的專業評審團會從獲嘉許計劃的企業及機構當中選出優秀實踐案例，為其他企業及機構提供良好示範，激發業界創意，推動企業機構業務蓬勃發展同時促進員工身心健康，建構企業「一」起動的活力企業文化。",
    title: '2021年主題: 《「疫」要「一」起動》',
    tip:
      '新冠肺炎大流行無容置疑為各企業或多或少帶來損失，反映僱主需要更注重員工的身心健康。 近一年以來整個商界面對突如其來的挑戰，要大規模地安排員工在家工作，這情況實前所未見。我們認為各企業更應藉此制定員工身心健康政策及其他相關活動，讓員工於大流行期間保持健康，亦可減少疫症對企業人力資源的影響。',
    date_tit: '時間線',
    date1: '2021年3月',
    date1_txt: '正式接受報名',
    date2: '2021年5月31日',
    date2_txt: '截止報名',
    date3: '2021年6月',
    date3_txt: '評審進行評核',
    date4: '2021年7月',
    date4_txt: '第一屆頒獎典禮',
    table : {
        date_1 : '2023年3月底',
        desc_1 : '起動活動：「職」時「一」起動',
        date_2 : '2023年4月1日',
        desc_2 : '接受報名',
        date_3 : '2023年5月15日',
        desc_3 : '早鳥優惠完結',
        date_4 : '2023年4月至5月',
        desc_4 : 'Thought Leadership系列分享會',
        date_5 : '2023年4月至6月',
        desc_5 : '免費運動課堂',
        date_6 : '2023年6月30日',
        desc_6 : '截止報名',
        date_7 : '2023年7月至8月',
        desc_7 : '評審進行評核',
        date_8 : '2023年9月',
        desc_8 : '結果公布及嘉許',
      },
    objective : '計劃目的',
    objective_txt : {
      title_1 : '員工 ',
      description_1 : '鼓勵企業及機構，促進員工及其家人建立每天「一小時」體能活動的生活習慣。',
      title_2 : '企業及機構 ',
      description_2 : '嘉許企業及機構積極建立活力企業圈，為社會提供優秀實踐案例，激發業界創意。',
      title_3 : '社會',
      description_3 : '促進企業及機構支持體育界發展，並以體能相關活動及捐獻回饋社會。',
      endObjective : '推動香港成為健康生活城市 '
    },
    achievment : '感激各界熱心打造活力工作環境，計劃首屆總共嘉許了99間來自不同行業及規模的企業及機構。',
    why : {
      heading : '為甚麼要成為《企業「一」起動》嘉許公司/ 機構？',
      subheading_1 : '打造活力團結的工作團隊',
      text_1 : '營造充滿活力的工作環境，保持員工的身心健康，從而提升士氣和歸屬感，也提升企業及機構的成效。',
      subheading_2 : '增加正面品牌效應',
      text_2 : '獲嘉許企業及機構會獲得本計劃標誌以用於傳訊物資及活動上，並獲本計劃策略媒體伙伴及相關媒體關注，增加正面品牌效應。',
      subheading_3 : '共享《企業「一」起動》網絡資源',
      text_3 : '獲嘉許企業及機構可於《企業「一」起動》網絡中，獲合作伙伴的資源支持及實踐案例作參考，在度身訂造活力工作團隊的過程中事半功倍。'
    },
    eligibility : {
      heading : '參加資格',
      el_text_1 : '於香港商業登記處註冊並成立至少1年的公司', 
      el_text_2 : '政府部門、法定機構、商會、專業機構、國際服務俱樂部，經稅務條例88條之下的註冊的慈善基金會、醫院、大學和教育機構',
      des : '首屆獲嘉許名單的標誌有效使用期，將於2023年9月30日到期。',
      des1 : '於2023-25年度獲嘉許名單的標誌有效使用期，則將於2025年9月30日到期。',
      btn1 : '《企業「一」起動》標誌及使用準則',
      btn2 : '《機構「一」起動》標誌及使用準則'
    },
    footer : {
      signup : '立即報名（網上版） ',
      download : '下載計劃小冊子',
      HKSTP: '../assets/images/footer/HKSTP_Chinese.png'
    },
    seminar: {
      signup: '立即報名'
    },
    kick_off: {
      desc: '《企業「一」起動》嘉許計劃2023-25已於3月30日正式啟動！當日除了有策略倡議夥伴周大福企業及CTFE Social Solutions的代表出席，我們亦很榮幸邀請到奧運級羽毛球港隊代表鄧俊文先生，以及首屆十間獲嘉許為優秀企業實踐案例的企業及機構，身體力行地參與啟動禮。'
    }
  },
  AboutUs: {
    title: '關於《企業「一」起動》嘉許計劃',
    tit1: '我們的使命',
    txt:
      '由凝動香港體育基金於2021年創立及推出， <strong>《企業「一」起動》嘉許計劃</strong>的目標是鼓勵香港企業及機構建立充滿健康活力的工作環境，由僱主帶動員工及其家人每天共參與「一小時」體能活動，推動員工的身心健康從而提升企業的效益。與此同時，計劃希望促進企業及機構以體育回饋社會，建立大眾健康生活意識，連結社會各界為全城身心健康出一分力。',
    tit2: '計劃目的',
    tit2_txt1:
      '鼓勵企業及機構促進員工及其家人每天共參與「一小時」體能活動，建立員工健康生活習慣，提升企業的效益',
    tit2_txt2: '促進企業及機構支持體育界發展以及以體能相關活動及捐獻回饋社會',
    tit2_txt3:
      '嘉許及彰顯企業及機構積極建立活力企業圈，並為香港社會提供良好示範及優秀實踐案例，激發業界創意，推動香港成為健康生活城市',
    tit3: '企業/機構參與誘因：',
    tit3_txt1:
      '人力資源是任何規模的企業及機構中最具價值的資產。 營造充滿活力的工作環境可以改善員工的身心健康從而提升企業及機構的成效。同時，企業持分者包括員工、投資者、客戶、供應商及其他相關伙伴對企業及機構的歸屬感皆會提升',
    tit3_txt2:
      '獲嘉許企業及機構會獲得本計劃標誌於企業及機構傳訊物資及活動上，並同時獲本計劃策略媒體伙伴及相關媒體關注，增加正面品牌效應',
    tit3_txt3:
      '獲嘉許企業及機構可於《企業「一」起動》的網絡中獲取實用的資源交流及出色的實踐案例為自家企業/機構度身訂造符合自家管理理念的《企業「一」起動》方案',
    tit4: '參加資格',
    tit4_txt1: '於香港商業登記處註冊並成立的公司至少一年<br>或',
    tit4_txt2:
      '政府部門、法定機構、商會、專業機構、國際服務俱樂部，經稅務條例88條之下的註冊的慈善基金會、醫院、大學和教育機構',
    tit5: '關於主辦機構',
    tit5_txt:
      '凝動香港體育基金是根據《稅務條例》第88條獲豁免繳稅的慈善團體，於 2012 年成立，一直秉承「以體育造就青年」的願景，致力透過體育提升基層青年的社會流動力，並同時宣揚性別平等、社會共融和健康生活。本會通過長期培訓、中期項目和短期健康生活活動三大範疇來履行使命。於2019-2020年，凝動為超過2,500位學員提供不少於46,000小時的專業體育培訓，另有超過4,000位青少年及兒童受惠於短期健康生活活動。',
    tit6: '關於策略倡議夥伴',
    tit6_txt:
      '周大福企業有限公司（簡稱「周大福企業」）是香港鄭氏家族始創於1929年的周大福集團旗下的私人投資控股旗艦公司。作為大型家族投資者, 周大福企業的投資領域包括：能源、飛機租賃、醫療保健、教育、媒體、酒店及大型綜合地產項目，以及以優質增長型科技企業為主的私募股權投資和一系列股票和債券流動投資組合。目前, 周大福企業及其附屬和合營公司的業務遍及全球超過24個國家。周大福企業以「我們投資於信任，為後世啟迪未來」為願景，在與投資業務共同成長的同時，一起為社會創造價值。',
  },
  Judges: {
    tit: '優秀企業實踐案例專業評審團',
    tip:
      '優秀企業實踐案例會由獨立的評審委員會選拔，該評審委員會由備受尊重的企業領袖、社會領袖、商業和金融領域的專家組成。',
    tip_2: '優秀企業實踐案例由獨立的評審委員會選拔，該評審委員會由備受尊重的企業領袖、社會領袖、人力資源、學界、商業和金融領域等專家組成。 第二屆的評審人數增加至13位。',
    txt: '(按英文姓氏字母序排列)',
    name1: '方敏生女士',
    name2: '霍啟剛先生',
    name3: '馮康醫生',
    name4: '林正財醫生',
    name5: '李翠莎博士',
    name6: '盧永仁博士',
    name7: '梅詩華女士',
    name8: '倪以理先生',
    name9: '詹康信先生',
    name10: '曾安業先生',
    name11: "李文龍先生",
    txt1: 'BBS, JP',
    txt2: 'JP',
    txt3: 'JP',
    txt4: 'SBS, JP',
    txt5: 'PhD, JD, SBS, BBS',
    txt6: 'JP',
    txt7: '<br>',
    txt8: '<br>',
    txt9: 'GBS',
    txt10: '<br>',
    txt11:'<br>',
    de1: '良管善治工作室創辦人',
    de2: '中國香港體育協會暨奧林匹克委員會副會長',
    de3: '香港中文大學醫院執行董事及行政總裁',
    de4: '香港特別行政區行政會議非官守議員',
    de5: '香港體育學院院長',
    de6: '電視廣播有限公司之獨立非執行董事及審核委員會主席',
    de7: '設計總監，一口設計工作室',
    de8: '麥肯錫公司資深董事兼大中華區總裁',
    de9: '嘉柏環球有限公司主席兼創辦人',
    de10: '周大福企業有限公司行政總裁',
    de11: '恒生銀行策略及企業發展總監兼行政總裁辦公室主任',
    // judges 2023 start
    new_name1:'陳麗雲教授',
    new_txt1 : 'PhD, RSW, FHKPCA, FHKASW, FAOSW,  JP',
    new_de1: '香港大學社會工作及社會行政學系榮休教授',
    new_name2:'陳肇始教授',
    new_txt2 : 'GBS, JP',
    new_de2: '<p style="font-size:14px">香港大學護理學教授</p><p style="font-size:14px">香港大學校長辦公室資深顧問</p>',
    new_name3:'張國麟先生',
    new_txt3 : '',
    new_de3: '一口設計工作室共同創辦人及總監',
    new_name4:'馮康醫生',
    new_txt4 : 'JP',
    new_de4: '香港中文大學醫院執行董事及行政總裁',
    new_name5:'林正財醫生',
    new_txt5 : 'SBS, JP',
    new_de5: '香港特別行政區行政會議非官守議員',
    new_name6:'李翠莎博士',
    new_txt6 : 'PhD, JD, SBS, BBS',
    new_de6: '香港體育學院院長',
    new_name7:'李文龍先生',
    new_txt7 : '',
    new_de7: '恒生銀行策略及企業發展總監兼行政總裁辦公室主任',
    new_name8:'盧永仁博士',
    new_txt8 : 'JP',
    new_de8: '大師集團有限公司創辦人及主席',
    new_name9:'呂詠姸小姐',
    new_txt9 : 'FIHRM (HK)',
    new_de9: '香港人力資源管理學會理事會成員',
    new_name10:'倪以理先生',
    new_txt10 : 'JP',
    new_de10: '麥肯錫公司資深董事兼大中華區主席',
    new_name11:'龐燕妮小姐',
    new_txt11 : '',
    new_de11: '奧雅納東亞區人力資源董事',
    new_name12:'詹康信先生',
    new_txt12 : 'GBS',
    new_de12: '嘉柏環球有限公司主席兼創辦人',
    new_name13:'曾安業先生',
    new_txt13 : '',
    new_de13: '周大福企業有限公司行政總裁',
  },
  Details: {
    // table:"報名表格",
    title: '獲嘉許條件',
    header: '評審範疇',
    tip:
      '第二屆《企業「一」起動》嘉許計劃將於2023年4月1日至6月30日接受報名。有意為團隊帶來改變並注入活力的你，立即起動！',
    table_tit: '公司規模',
    employees1: '少於50名員工',
    employees2: '50-500名員工',
    employees3: '多於500名員工',
    basic_scopes: '基本評審範疇',
    practices_showcase: '優秀企業實踐案例',
    scope1: '範疇一',
    scope2: '範疇二',
    scope3: '範疇三',
    table_header_1: '範疇一',
    table_header_2: '範疇二',
    table_header_3: '範疇三',
    table_header_4: '總要求數目',
    table_header_5: '額外範疇要求數目',
    total: '總要求數目',
    txt1:
      '公司/機構擬參與優秀企業實踐案例嘉許標準，必須先達以上《企業「一」起動》嘉許標準，並同時符合大會',
    txt2:
      "首屆主題範疇<span style='color:#1E30F6'>《「疫」要「一」起動》</span>以下最低達標要求：",
    cofit1: "<strong style='color:#1E30F6'>《「疫」要「一」起動》</strong>",
    cofit2: '優秀企業實踐案例嘉許範疇',
    scope: '範圍',
    scope1_tit: '創造「一」起動的工作環境',
    scope1_txt:
      '為員工創造運動友善，猶如在家一般的環境，促進他們「職」時進行體能活動。',
    scope2_tit: '鼓勵員工「一」起動',
    scope2_txt:
      '為員工提供額外資源，鼓勵員工積極參與體能活動，從而建立「一起動」的企業文化。',
    scope3_tit: '「一」起動連結回饋社會',
    scope3_txt:
      '與本地體育組織、慈善機構等合作，以體育連結社會大眾，為香港社會建立健康生活文化。',
    scope_footer_tit1: '優秀企業實踐案例首屆主題範疇：',
    scope_footer_tit2:
      "<span style='color:#1E30F6;font-weight: 600;'>《「疫」要「一」起動》</span>",
    scope_footer_txt:
      '公司因應疫情為員工及社區特別安排的回應措施，以支持員工及社區持續參與體能活動。',
    join_tit: '*有關各項準則的詳細資料，請細閱報名表格。',
    apply: '按此下載報名表格',
    join_date: '報名方法及截止日期',
    join_tip: '首屆已完滿結束，第二屆申請詳情將於2023年公布，請密切留意！',
    email: 'sportshourcompany@inspiringhk.org',
    address1: '英皇道663號泓富產業千禧廣場9樓911室',
    address2: '(信封面請註明企業「一」起動報名表格)',
    apply_detail: '*有關各項準則的詳細資料，請細閱報名表格。',
    signup: '立即報名（網上版）',
    download: '計劃小冊子',
    event: '活動與研討會',
    best_practice_title: '「優秀企業實踐案例」額外範疇',
    best_practice_slogan: '《職時心動》',
    best_practice_content: '激發業界創意支援員工參與運動保持精神健康，創建活力也投入的工作團隊，從而提升企業生產力及聲譽。',
    success_recipient_1: '為鼓勵不同規模的企業及機構，按其資源打造活力工作環境，計劃按公司規模設三個組別。在三個基本評審範疇，企業或機構只需按其規模分別達到要求，便可獲嘉許為《企業「一」起動》企業或機構。',
    success_recipient_2_1: '若有意報名成為優秀企業實踐案例，則需在基本評審範疇和額外範疇《',
    success_recipient_2_2: '「職」時心動 ',
    success_recipient_2_3: '》皆達標。範疇詳情可參考計劃小冊子。',
  },
  CompanyList: {
    complist_tit1: '首屆《企業「一」起動》嘉許計劃獲嘉許名單',
    complist_tit2: '少於 50 名員工之企業',
    complist_tit2_1: '以企業英文名稱排列',
    complist_tit3: '少於 50 名員工之機構',
    complist_tit3_1: '以機構英文名稱排列',
    complist_tit4: ' 51	- 500 名員工之企業	',
    complist_tit4_1: '以企業英文名稱排列',
    complist_tit5: '51	- 500 名員工之機構		',
    complist_tit5_1: '以機構英文名稱排列',
    complist_tit6: '多於 500 名員工之企業',
    complist_tit6_1: '以企業英文名稱排列',
    complist_tit7: '多於 500 名員工之機構',
    complist_tit7_1: '以機構英文名稱排列',
    complist_tit1_2023: '《企業「一」起動》嘉許計劃 2023-25 獲嘉許企業／機構名單',
    topTitle: '「職」時心動優秀企業／機構實踐案例'

  },
  COFITList: {
    co_tit1: '《「疫」要「一」起動》優秀企業／機構實踐案例嘉許名單',
    co_tit2: '以企業／機構 英文名稱排列',
    co_tit3: '少於 50 名員工之企業／機構',
    co_tit4: '50 - 500 名員工之企業／機構	',
    co_tit5: '多於 500 名員工之企業／機構',
  },
  COFITcompany: {
    title: '「職」時心動優秀企業／機構實踐案例',
    close: '關閉',
    company_name1: '亞洲運動及體適能專業學院有限公司',
    company_vid_desc_1: '亞洲運動及體適能專業學院（AASFP）提供不少體育用品資源予同事，例如瑜伽球以代替椅子。每星期有專責同事推出特有的Fitograph，分享體適能資訊。於去年2月至4月期間，學院啟動「疫」境自強社區免費運動班計劃，免費教導30位健身教練，如何在公園教導不同年紀的市民做運動，也在9個地區舉辦義務運動班。',
    company_name2: '中國香港跳繩體育聯會有限公司',
    company_vid_desc_2 : '在疫情期間，中國香港跳繩體育聯會為保持員工有充足運動，維持身心健康，讓員工於工作期間，按個人興趣以不同運動形式舒緩壓力，例如跳繩、跳舞及體操動作，同時減少員工與其他公眾共用使用運動設備的機會，特別增購不同運動器材，例如小型足球龍門架、乒乓球套裝、拳擊沙包及小型籃球套裝等。',
    company_name3: 'Terra Forma Limited',
    company_vid_desc_3: '由去年開始，我們每逢周二及周四在午膳時間前為員工舉辦定期健身訓練。我們還會為員工提供制服贊助，每位員工在每個月可以獲得一對免費的運動鞋，讓他們隨時隨地有最佳的裝備去健身或運動。每逢周日我們亦為 LANE EIGHT 社群舉行免費的健身訓練班，公眾只需要在網上報名就可以參加。',
    company_name4:
      'adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
    company_vid_desc_4 : '實踐"CO-FIT"的關鍵在於使用虛擬及數碼化平台，令員工及社區保持聯繫。我們於辦公室內舉辦瑜伽和普拉提等課程，同時開放數碼化平台，讓在家工作的員工也可以參與。此外，愛廸達通過共融和互動性的競賽和挑戰拉近員工及部門之間的距離。 其中一個挑戰是「步數競賽」，各部門需要在指定時間內完成最多的步數，完成後向本地慈善機構作捐贈。',
    company_name5: '藍十字（亞太）保險有限公司',
    company_vid_desc_5 : '在疫情期間，藍十字考慮到員工的身心健康，採取不同措施以支持他們持續參與健康體能活動，例如舉辦網上康體工作坊和運動員網上分享等，以及為員工增加每月一次的早休，讓他們可以更有彈性地安排更多工作以外的活動。',
    company_name6: '葛蘭素史克有限公司',
    company_vid_desc_6: '葛蘭素史克以  “Resilience” （抗疫復原力）為主題，建立一系列措施及活動，以助員工在抗疫期間繼續保持身心健康，包括：Resilience Time ，每月及節日的提早休假、Resilience Friday 運動項目，提供網上健身運動課程，如全身拉筋操、普拉提 、送贈Virgin Pulse GO給員工以鼓勵鍛練身體。',
    company_name7: '捷成洋行有限公司',
    company_vid_desc_7: '因應新冠疫情，捷成集團的各種員工活動都移師到網上舉行。以每年一度的「健康月」為例，集團為全體同事派發運動套裝。去年捷成與凝動香港體育基金合作，四星期裡每週發佈不同主題和難度的運動影片給同事觀看，並鼓勵他們加入挑戰，在空餘時間或在家工作時按照指示完成相關運動和拍成影片。',
    company_name8: '香港中華煤氣有限公司',
    company_vid_desc_8: '因應疫情期間的社交距離措施，煤氣公司把活動由線下改成線上，又特別拍攝Home Fitness 運動課程短片，方便員工隨時隨地於家中進行運動。公司亦設有公共空間和體能活動區域並提供多款運動器材供員工使用，鼓勵他們養成積極、恆常的運動習慣。疫情期間，公司更設有彈性上班時間政策，同事亦可選擇彈性午餐時間，配合自己的運動習慣',
    company_name9: '九龍巴士（一九三三）有限公司',
    company_vid_desc_9: '<p>受疫情影響，九巴結合創意與科技，轉以線上方式推廣運動，包括：</p><br><ul style="list-style:none"><li>- 舉辦平板支撐挑戰賽、網上講座，鼓勵員工繼續恆常運動，注意均衡飲食；</li><li>- 成立新興趣會「樂行會」，鼓勵員工遠足，並製作《樂活‧優遊》電子天書及短片，介紹熱門郊遊地點；</li><li>- 參加生命熱線「愛‧喜‧行」線上慈善行；及</li><li>- 製作「九巴齊心抗疫：做運動、身體好」WhatsApp貼紙。</li></ul>',
    company_name10: '香港大學',
    company_vid_desc_10: '疫情時，香港大學透過「運動良藥」校園平台  (www.eim.cse.hku.hk/)，為學生和教職員在疫情期間也能夠保持運動以維持體魄。「運動良藥」提供了各款以不同強度的伸展練習的單張；製作適合不同體能程度及能力的網上練習短片，讓參與者在家中或辦公室進行活動。此外，大學體育設施亦開放給予全職員工及其家屬免費使用',
    employees_50: '少於50名員工之企業／機構',
    employees_500: '50 - 500名員工之企業／機構',
    employees_5000: '多於500名員工之企業／機構',
  },
  COFITcompany2023: {
    company_name1: '亞洲運動及體適能專業學院有限公司',
    company_vid_desc_1: 'AASFP一直支持「運動產業化」，近年大力支持體育機構、俱樂部、學校舉辦的體育活動或賽事，合作機構包括東方體育會、香港體育舞蹈總會、HKUBA全港中學及大專聯校健體比賽、黑武士失明人體育會及凝動香港體育基金等。我們亦相當鼓勵同事帶同子女上班，甚至會在辦公室一起做運動；並免費讓他們參加工作坊，例如「小小物理治療師」體驗工作坊。',
    company_name2: '​​啟德體育園',
    company_vid_desc_2: '啟德體育園提供各種可隨時進行的體育活動，讓員工達致Unwind and Play! Sports Anytime All Day! 我們的辦公室設有健身室，同事可以隨時隨心地舒展身心。此外，我們亦向同事送出活動門票，為同事提供與親友體驗運動、共聚天倫的機會，更可讓家人了解自己的工作，以提升家庭凝聚力，減少生活壓力。',
    company_name3: 'Time Auction Limited',
    company_vid_desc_3: 'Time Auction是一個推動義工文化的慈善機構，對外以具啟發性的體驗鼓勵義工服務，在內亦著重創造積極的工作文化和氛圍，提高員工的身心靈健康，有助於他們的個人和職業成長。定期舉辦各種運動和舒壓活動，可以令員工藉此放鬆身心，減輕工作壓力和焦慮，提高整體自我肯定和幸福感。因此，我們一直秉持著健康的運動生活文化。',
    company_name4: 'adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
    company_vid_desc_4: 'adidas 的文化由我們的宗旨 「Through Sport, We Have the Power to Change Lives」所塑造。我們提供各種福利計劃，幫助員工保持健康、活力和參與性。<br />在上班時間，我們提供各種體育活動，包括瑜伽、普拉提、舞蹈、跆拳道和排球。我們還提供溜冰、保齡球和抱石等體驗課程，以擴大員工接觸新興運動的機會。這些活動在公司內多用途空間或附近的運動場進行，辦公室內還備有運動器材和淋浴室以便員工更投入運動。為了提供靈活性，我們還為員工提供辦公室以外的福利開支津貼，如運動課程、健身房會籍、運動器材等。',
    company_name5: '興勝創建控股有限公司',
    company_vid_desc_5: '興勝創建早於發展初期已成立多個委員會，除發揮專責功能外，亦全方位關顧員工的身心健康。<br />集團照顧員工的全人健康與基本福祉，亦投資人才發展及培育。在管理層帶領下，集團鼓勵員工參與有益身心的活動，成立乒乓球、足球等運動隊伍，並聘請本地體育教練提供專業訓練。同時資助運動隊伍參與公開賽事，並嘉許獲獎員工，藉以提升他們的投入度，建立團隊合作及夥伴精神。',
    company_name6: '霍金路偉律師行',
    company_vid_desc_6: '霍金路偉律師行的香港辦公室積極響應全球健康策略，參與國際龍舟邀請賽、企業慈善籃球賽等的體育競技賽事。同時我們亦在公司内舉辦小型奧林匹克運動會，提供按摩活動、普拉提和瑜伽課程。在疫情期間，我們向每位同事派發彈力訓練帶，讓他們在家中也可以持續鍛煉。社會復常後，普拉提和瑜伽課程仍然深受各同事歡迎。',
    company_name7: '特斯拉汽車有限公司',
    company_vid_desc_7: '員工是我們最大的資產，我們致力成為一個大家每天都喜歡上班的工作場所。 多年來，我們鼓勵員工保持健康的生活方式，以不斷創新，讓世界變得更美好。<br />過去兩年，我們發起了多項創新的員工活動，積極推動各個方面的運動。 我們舉辦了多項體能活動，包括平板支撐挑戰、步行挑戰、體能接力賽、Tesla Center Run等，這些活動從個人挑戰到團體挑戰，從實體活動到線上活動皆有。 去年，我們甚至將互動擴增實景手機健身應用程序融入到我們的體能挑戰中，以豐富整體體驗並讓體能活動變得更加有趣。 ',
    company_name8: '中華電力有限公司',
    company_vid_desc_8: '「以人為本」是中華電力的核心價值之一，我們關注員工的健康，而員工的身心健康與工作表現亦息息相關，因此中華電力為員工提供多元化的運動項目及康體活動，更鼓勵同事將運動融入工作及生活，提升活力及正能量。',
    company_name9: '星展銀行（香港）有限公司',
    company_vid_desc_9: '星展銀行採用全面的福祉策略，為同事提供廣泛又全面的福利方案，務求令他們倍感關懷，從而實現 「Best of ME」, 「Best of WE」 和 「Best of E(nvironment)」 。我們會定期審視現行措施，確保我們能夠為同事提供全面的支援，滿足他們不同的需要。',
    company_name10: '香港上海滙豐銀行',
    company_vid_desc_10: '滙豐銀行一向以人為本。我們非常關注員工的身心健康。我們的員工褔祉策略涵蓋四個範疇，分別是身、心、社交和財務健康。我們相信每一個範疇都環環相扣，對員工的全面發展有莫大幫助。我們提升員工身心健康的措施主要包括宣傳、教育，以及提供適當的活動和設施'
  },
  Activities: {
    button:'按此下載場刊',
    title1: '《企業「一」起動分享會 (1) 》',
    title_cont:
      '兩場網上分享會分別於2021年4月16日及5月7日順利舉行，共有過超過兩百名來自160間企業及機構的代表參於網上直播，討論如何為企業和機構注入「動」力、營造有益身心和高效的工作環境和公私營機構如何在「新常態」下建立新「動」態企業文化。',
    content_one1:
      '凝動感激一眾嘉賓和評審百忙中抽時間參加分享會，包括：中國香港體育協會暨奧林匹克委員會副會長霍啟剛先生，太平紳士、凝動大使方力申先生、香港中文大學醫院行政總裁馮康醫生，太平紳士、捷成集團人力資源總監何佩珊女士、一口設計設計總監梅詩華女士、麥肯錫公司資深董事兼大中華區總裁倪以理先生，太平紳士、屈臣氏 集團（亞洲及歐洲）行政總裁倪文玲女仕，太平紳士、香港僱主聯合會總裁龐維仁先生、周大福企業有限公司行政總裁曾安業先生及領英北亞專才、學習與僱員事務主管王俊喬先生。',
    content_one2:
      '首場活動中嘉賓分享了不少實踐案例及員工傾聽的重要性。例如步行會議 (walking meeting) 和「座位鍛煉」(deskercise)等創意點子，都是不錯的執行方案！',
    content_one3:
      '儘管大家未必知道未來的「新常態」將是什麼樣，引用倪以理先生於第二場分享會所說：「其實這一『疫』就像體育比賽一樣，無論公司或組織的規模如何，運動都是韌性和優勝劣汰的終極考驗。」',
    content_one4:
      '「新常態」下推動「新動態」企業活力文化是一個最合適的時候轉危為機， 引入「一」起動或者加強活力企業文化，將負面便正面、將不可能變可能，令公司上下都充滿正能量迎難而上。斜槓族是近年熱門的職稱，如果僱主和僱員都可以做到運動／生活／工作，必定可以共同提高生活質量和工作效率！',
    title2: '《企業「一」起動分享會 (2) 》',
    title3: '《企業「一」起動》嘉許計劃及年度優秀實踐案例典禮',
    title3_txt:
      '首屆嘉許典禮已於2021年9月9日假柴灣青年廣場圓滿舉行。典禮齊集各界不同規模的公司或機構代表，共有超過250名管理人員連同行政會議非官守議員林正財醫生, SBS, JP、商務及經濟發展局副局長陳百里博士、周大福企業有限公司行政總裁曾安業先生、麥肯錫公司資深董事兼大中華區總裁倪以理先生，以及已轉戰銀行界，現任職於瑞銀集團（香港）的兩屆奧運會香港隊代表、男子4×100公尺香港紀錄保持者黎振浩先生出席。',
    title3_content1:
      '超過250位活力企業文化的先鋒聚首一堂，向社會各界宣揚「每日運動一個鐘」的重要性。',
    title3_content2:
      '管理層穿著一身運動休閒裝束出席嘉許典禮，展示出他們的活力！',
    title3_content3:
      '行政會議非官守議員林正財醫生為「單車發燒友」，活動後於社交媒體上亦大力支持「每日運動一個鐘」。',
    title3_content4:
      '商務及經濟發展局副局長陳百里博士表示，政府將會透過不同平台，繼續鼓勵市民多做運動。',
    title4: '計劃宣傳',
  },
  Ceremony: {
    title:
      '首屆嘉許典禮已於2021年9月9日假柴灣青年廣場圓滿舉行。典禮齊集各界不同規模的公司或機構代表，' +
      '共有超過250名管理人員連同行政會議非官守議員林正財醫生, SBS, JP、商務及經濟發展局副局長陳百里博士、周大福企業有限公司行政總裁曾安業先生、麥肯錫公司資深董事兼大中華區總裁倪以理先生，以及已轉戰銀行界，現任職於瑞銀集團（香港）的兩屆奧運會香港隊代表、男子4×100公尺香港紀錄保持者黎振浩先生出席。',
    btn: '下載場刊',
    btn_watch: '觀看短片',
    title_23_25:
      '「SportsHour研討會暨嘉許典禮」已於2023年9月25日假香港金融大會堂舉行，以表揚業界管理層在推動員工建立健康生活方面的努力。在第二屆計劃，136個單位獲嘉許為「一」起動企業及機構，比首屆嘉許數目上升四成。典禮當日亦有超過200位企業代表和以下嘉賓出席。<br>\
      <br>\
      <b>主禮嘉賓</b><br>\
      •	香港賽馬會董事 黃嘉純先生，SBS，JP 主題演講<br>\
      <br>\
      <b>討論嘉賓 (按英文姓氏排序)</b><br>\
      •	陳韻湘女士 - 周大福企業首席營運總監<br>\
      •	陳曉慧女士 - 高奧士國際有限公司 董事總經理<br>\
      •	吳薇薇女士 - LinkedIn Hong Kong Limited 總經理<br>\
      <br>\
      <b>頒獎嘉賓 (按英文姓氏排序)</b><br>\
      •	陳肇始教授 - 香港大學護理學教授 香港大學校長辦公室資深顧問<br>\
      •	呂詠妍小姐 - 香港人力資源管理學會理事會成員<br>\
      •	龐燕妮小姐 - 奧雅納東亞區人力資源董事<br>',
    download_brochure: '下載場刊'
  },
  Seminar: {
    subTitle: '兩場網上分享會分別於2021年4月16日及5月7日順利舉行，共有過超過200名來自160間企業及機構的代表參於網上直播，討論如何為企業和機構注入「動」力、營造有益身心和高效的工作環境和公私營機構如何在「新常態」下建立新「動」態企業文化。',
    subTitle1: '《企業「一」起動分享會 (1) – 為你的公司注入「動」力》',
    content:'首場活動中嘉賓分享了不少實踐案例及員工傾聽的重要性。例如步行會議 (walking meeting) 和「座位鍛煉」(deskercise)等創意點子，都是不錯的執行方案。',
    captions:{
      content_one1:
          '凝動感激一眾嘉賓和評審百忙中抽時間參加分享會，包括：中國香港體育協會暨奧林匹克委員會副會長霍啟剛先生，太平紳士、凝動大使方力申先生、香港中文大學醫院行政總裁馮康醫生，太平紳士、捷成集團人力資源總監何佩珊女士、一口設計設計總監梅詩華女士、麥肯錫公司資深董事兼大中華區總裁倪以理先生，太平紳士、屈臣氏 集團（亞洲及歐洲）行政總裁倪文玲女仕，太平紳士、香港僱主聯合會總裁龐維仁先生、周大福企業有限公司行政總裁曾安業先生及領英北亞專才、學習與僱員事務主管王俊喬先生。',
      content_one2:
          '首場活動中嘉賓分享了不少實踐案例及員工傾聽的重要性。例如步行會議 (walking meeting) 和「座位鍛煉」(deskercise)等創意點子，都是不錯的執行方案！',
      content_one3:
          '儘管大家未必知道未來的「新常態」將是什麼樣，引用倪以理先生於第二場分享會所說：「其實這一『疫』就像體育比賽一樣，無論公司或組織的規模如何，運動都是韌性和優勝劣汰的終極考驗。」',
      content_one4:
          '「新常態」下推動「新動態」企業活力文化是一個最合適的時候轉危為機， 引入「一」起動或者加強活力企業文化，將負面便正面、將不可能變可能，令公司上下都充滿正能量迎難而上。斜槓族是近年熱門的職稱，如果僱主和僱員都可以做到運動／生活／工作，必定可以共同提高生活質量和工作效率！',
    },
    subTitle2: '《企業「一」起動分享會 (2) – 「疫」要「一」起動',
    content2: '疫情改變工作常態，同事更常留家，甚少外出活動。期間公司要保持同事活躍參與體能活動，更是難上加難。故《「疫」要「一」起動》研討會邀請政商和學術界的專家分享心得，望激發業界創意，在「新常態」下建立新「動」態企業文化。',
    signup: '立即報名',
    text_23_25: '為了發掘運動在企業管理和發展能擔任的角色，我們邀請了各界的嘉賓講者，包括運動、人力資源、科技及商界代表，舉辦了三場網上分享會。期間，我們也安排七場免費運動體驗，讓有興趣人士可報名並探索平日做運動的可能性。',
    title_23_25 :'四月至六月',
    subtitle_sport :'運動體驗班 ( 7節）',
    subtitle_leadership: '思想領䄂系列（3節）'
  },
  Feature: {
    fea_txt1: '萃聞萃語: 鄭萃雯 工作時運動',
    fea_media1: 'AM730',
    fea_date1: '2021年4月7日',
    fea_txt2: ' 機構推嘉許計劃 鼓勵職場康體文化 有企業讓員工提早放工做運動 ',
    fea_media2: '香港01',
    fea_date2: '2021年4月9日',
    fea_txt3: ' 點畫人生——讓工作文化注入「動」力',
    fea_media3: '頭條日報',
    fea_date3: '2021年4月9日',
    fea_txt4: ' 企業辦康體活動　霍啟剛：助員工溝通 ',
    fea_media4: '星島日報',
    fea_date4: '2021年4月10日',
    fea_txt5: ' 健兒轉型 ',
    fea_media5: '頭條日報',
    fea_date5: '2021年9月10日',
    fea_txt6:
      " Hong Kong and China Gas : Towngas recognised as 'CO-FIT' Best Practice Showcase Company for advocating sports culture for staff mental and physical health ",
    fea_media6: 'MarketScreener',
    fea_date6: '2021年9月10日',
    fea_txt7: ' 《企業「一」起動》嘉許計劃頒獎禮 99間公司獲嘉許 am730榜上有名 ',
    fea_media7: 'am730 ',
    fea_date7: '2021年9月13日',
    fea_txt8: ' 企業一起動，你今日動左未 ',
    fea_media8: '香港開電視',
    fea_date8: '2021年9月15日',
    fea_txt9: '「企業一起動」嘉許計劃展開',
    fea_media9: '有線新聞',
    fea_date9: '2023年3月30日'
  },
  Together:{
    move_tog: ' 如任何企業或機構有興趣透過凝動舉辦體育活動或課堂，歡迎致電56056013聯絡胡小姐',
    read: '按此了解更多'
  },
  sponsor:{
    title1: '策略倡議夥伴',
    title2: '支持夥伴',
    title3: '數碼夥伴',
    title4: '學習夥伴',
    title5: '媒體夥伴',
    title6: '創意夥伴',
    tips: '(按英文字母順序排列)'
  },
  kick_off: {
    desc: '《企業「一」起動》嘉許計劃2023-25已於3月30日正式啟動！當日除了有策略倡議夥伴周大福企業及CTFE Social Solutions的代表出席，我們亦很榮幸邀請到奧運級羽毛球港隊代表鄧俊文先生，以及首屆十間獲嘉許為優秀企業實踐案例的企業及機構，身體力行地參與啟動禮。'
  },
  ...cnLocale,
}
export default cn
