import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  header: {
    nav1: 'The Scheme',
    nav2: 'Judging Criteria',
    nav3: 'Recognised List',
    nav4 : 'Best Practices Showcase',
    nav5: 'Panel of Judges',
    nav6: 'Events',
    nav7: 'Sponsors and Partners',
    nav8: 'Move Together!',
    nav9: 'Media Exposure',
    coming: 'Coming Soon',
    nav6_desc: 'Stay tuned for our upcoming events!',
    banner_nav: 'https://www.change4health.gov.hk/en/physical_activity/10000_steps_a_day/index2.html'
  },
  MenuItems: {
    nav1: '2023-25',
    nav2: '2021-23 (Inaugural)',
    nav3 : 'ProFITional Body, ProFITional Mind" Best Practices Showcase',
    nav4 : 'CO-FIT" Best Practice Showcase',
    nav5 : 'Ceremony',
    nav6 : 'Events',
    nav7 : 'Kick off Event',
    nav8 : '2023-25'
  },
  Footer: {
    footer1: 'Organiser',
    footer2: 'Lead Advocacy Partner',
    footer3: 'Digital Partner',
    footer4: 'Supporting Partner',
    footer5: 'Learning Partner',
    footer6: 'Media Partner',
    footer7: 'Creative Partner'
  },
  Home: {
    apply_btn: 'Apply Now',
    scheme: 'The Scheme',
    scheme_desc: 'Founded and launched by InspiringHK Sports Foundation in 2021, the SportsHour Company Scheme encourages Hong Kong companies and organisations to promote employees and family members’ participation in  one hour of physical activities daily, fostering a healthy lifestyle as part of the company culture thus improving business efficiency. The scheme also encourages companies and organisations to support the development of the local sports industry and to give back to the sports community in Hong Kong.',
    download_desc: 'If you are unable to fill in online, download the application form ',
    download_desc1 : 'here',
    download_desc2 : ' .',
    btn_1 : 'Sign Up Now (Online Form)',
    btn_2 : 'Download Scheme Leaflet',
    btn_3 : 'Events and Seminars',
    finish:
      ' * Inaugural SportsHour Company Scheme was ended! Thanks for your support! Please click',
    finish1: 'here',
    finish2: 'for the ceremony highlights.',
    result: 'Inaugural Recognised SportsHour Companies and Organisations List',
    apply_bottom_btn: 'Download Scheme Brochure',
    apply_date: '*Application deadline is 31st May',
    txt1:
      'InspiringHK Sports Foundation has founded and launched the <strong>SportsHour Company Scheme & Best Practice Showcase</strong> in 2021, to encourage Hong Kong companies and organisations to promote employees and family members’ participation in <strong>one hour</strong> of physical activities daily, fostering a healthy lifestyle as part of the company culture thus improving business efficiency. The scheme also encourages companies and organisations to support the development of the local sports industry and to give back to the sports community in Hong Kong.',
    txt2:
      "The inaugural Scheme targets to recognise over 100 companies and organisations, from the business and public sectors, that have been promoting active living for employees in Hong Kong. On top of the scheme application is a Best Practice Showcase. The first year’s thematic scope <span style='color: #1e30f6;'>“CO-FIT”</span> calls for examples of how the sudden onset of the largest work-from-home experiment has altered work environments and how companies and organisations supported employees to stay active amid COVID-19. An honorable judging panel formed by 10 leaders from various sectors will select outstanding practices that embody the scheme which are inspiring, creative and innovative.",
    title: '2021 Theme: CO-FIT',
    tip:
      'The COVID-19 pandemic has definitely highlighted the need for employers to prioritise their employees’ health and wellbeing - both in terms of physical and mental health - as the virus takes a toll. We understand the sudden onset of the largest work-from-home experiment of all time has altered work environments in ways unimaginable a few years ago, if not months. In response, organisations and companies have been creating wellness programmes and a host of other initiatives to support employees to stay active amid the coronavirus pandemic in 2021. ',
    date_tit: 'TIMELINE',
    date1: '1st March 2021',
    date1_txt: 'Start of Application',
    date2: '31st May 2021',
    date2_txt: 'Application Deadline',
    date3: 'June 2021',
    date3_txt: 'Application Review by Judging Panel',
    date4: 'July 2021',
    date4_txt: '1st year Scheme Award Ceremony',
    table : {
      date_1 : '30 March 2023',
      desc_1 : 'Kick-off Event: Let’s Get Active',
      date_2 : '1 April 2023',
      desc_2 : 'Application starts',
      date_3 : '15 May 2023',
      desc_3 : 'Early Bird discount ends',
      date_4 : 'April - May 2023',
      desc_4 : 'Thought Leadership Series sharings',
      date_5 : 'April - June 2023',
      desc_5 : 'Free sports sessions',
      date_6 : '30 June 2023',
      desc_6 : 'Application closes',
      date_7 : 'July - August 2023',
      desc_7 : 'Judges review',
      date_8 : 'September 2023',
      desc_8 : 'Announcement of results and recognition',
    },
    objective : 'Objective',
    objective_txt : {
      title_1 : 'Employee',
      description_1 : 'Encourages companies and organisations to promote the participation in one hour of physical activities daily to employees and family members.',
      title_2 : 'Companies and Organisations',
      description_2 : 'Recognises companies and organisations’ contribution to building an active community, providing good and creative best practices for the industry, promoting Hong Kong as a healthy living city.',
      title_3 : 'The Community',
      description_3 : 'Encourages companies and organisations to support the development of the sports industry and to give back to the sports community through the participation in physical activities, events or contribute through monetary or in-kind donations.',
      endObjective : 'Building Hong Kong into an active city'
    },
    achievment : 'The inaugural Scheme has recognised over 99 companies and organisations, from the business and public sectors and of different sizes, that have been promoting active living for employees in Hong Kong.',
    why : {
      heading : 'Why become a SportsHour Company / Organisation?',
      subheading_1 : 'Create an active team',
      text_1 : 'Promoting an active workplace would benefit the physical and mental wellbeing of employees, hence building a greater sense of belonging and accelerating the performance of the company.',
      subheading_2 : 'Build a positive brand image',
      text_2 : 'Recognised companies and organisations will receive a scheme logo to be used on communication materials and activities. They will also be featured by strategic partners and related platforms to build a positive brand image.',
      subheading_3 : 'Be a part of the SHC Network',
      text_3 : 'Fostering collaboration among the network of scheme recipients so tailor-made solutions can then be made to fit various organisational needs'
    },
    eligibility : {
      heading : 'Eligibility',
      el_text_1 : 'Companies registered with Business Registration and established in Hong Kong for at least 1 year', 
      el_text_2 : 'Government departments, statutory bodies, chambers of commerce, professional bodies, international service clubs, philanthropic foundations, under section 88 of the Inland Revenue Department, hospitals, universities and educational institutions are eligible to apply',
      des : 'For the inaugural recognised list of companies and organisations, the eligibility period for logo usage will end on 30th September, 2023.',
      des1 :"For the recognised list 2023-25, the eligibility period for logo usage will end on 30th September, 2025.",
      btn1 : 'SportsHour Company Logo and Guideline',
      btn2 : 'SportsHour Organisation Logo and Guideline'
    },
    footer : {
      signup : 'Sign Up Now (Online Form)',
      download : 'Download Scheme Leaflet',
      HKSTP: '../assets/images/footer/HKSTP_English.png'
    },
    seminar: {
      signup: 'Sign Up Now',
    },
    kick_off: {
      desc: 'SportsHour Company Scheme 2023-25 was kicked off on 30th March! Thank the representatives from our lead advocacy partner Chow Tai Fook Enterprises and CTFE Social Solutions, the 10 Inaugural Best Practice Showcases, with the Olympian Badminton player Mr Tang Chun Man, for kicking off the Scheme with us.'
    }
  },
  AboutUs: {
    title: 'ABOUT SportsHour Company Scheme',
    tit1: 'Our Vision',
    txt:
      'Founded and launched by InspiringHK Sports Foundation in 2021,  <strong>SportsHour Company </strong> Scheme encourages Hong Kong companies and organisations to promote employees and family members’ participation in  <strong>one hour </strong> of physical activities daily, fostering a healthy lifestyle as part of the company culture thus improving business efficiency. The scheme also encourages companies and organisations to support the development of the local sports industry and to give back to the sports community in Hong Kong.',
    tit2: 'Objectives',
    tit2_txt1:
      'Encourages companies and organisations to promote the participation in one hour of physical activities daily to employees and family members. Fostering a healthy lifestyle as part of the company culture thus improving business efficiency',
    tit2_txt2:
      'Encourages companies and organisations to support the development of the sports industry and to give back to the sports community through the participation in physical activities, events or contribute through monetary or in-kind donations',
    tit2_txt3:
      'Recognises companies and organisations’ contribution to building an active community, providing good and creative best practices for the industry, promoting Hong Kong as a healthy living city',
    tit3: 'Benefits of Participation',
    tit3_txt1:
      'Human capital is the most valuable asset in any company or organisation, big and small. Promoting an active workplace would accelerate the performance of the company through improving the mental and physical wellbeing of their employees. Corporate stakeholders including employees, investors, customers, suppliers and other related partners will also have a greater sense of belonging ',
    tit3_txt2:
      'Recognised companies and organisations will receive a scheme logo to be used on communication materials and activities. They will also be featured by strategic fea_txt partners and related fea_txt platforms to build a positive brand image',
    tit3_txt3:
      'Fostering collaboration among the network of scheme recipients so tailor-made solutions can then be made to fit various organisational needs',
    tit4: 'ELIGIBILITY',
    tit4_txt1:
      'Companies registered with Business Registration and established in Hong Kong for at least 1 year<br> OR',
    tit4_txt2:
      'Government departments, statutory bodies, chambers of commerce, professional bodies, international service clubs, philanthropic foundations, under section 88 of the Inland Revenue Department, hospitals, universities and educational institutions are eligible to apply.',
    tit5: 'About the Organiser',
    tit5_txt:
      'InspiringHK Sports Foundation is a local charity established in 2012, with a vision of developing better youths through sports. Our mission is to promote social mobility, gender equality, social inclusion and healthy lifestyle through sports. We carry out our mission through long-term programme, medium-term projects and short-term healthy lifestyle activities. In 2019-2020, IHKSports has provided services to over 2,500 beneficiaries with not less than 46,000 hours of professional sports training. In addition, over 4,000 children and youth have benefitted from short-term healthy lifestyle activities.',
    tit6: 'About our Lead Advocacy Partner',
    tit6_txt:
      'Chow Tai Fook Enterprises Limited (“CTFE”) is the flagship private investment holding company of the Cheng Family and is part of the group of companies under the “Chow Tai Fook” name founded by the Cheng Family in 1929. CTFE is a premier family investor with strategic investments in energy, aircraft leasing, healthcare, education, fea_txt, real estate and hospitality services, and private equity investments in quality growth companies mainly in the technology sector and a liquid portfolio of equities and bonds. CTFE, together with its subsidiaries and jointly controlled entities, has operations in more than 24 countries globally. CTFE invests in trust to build an inspiring future for generations and is committed to growing with its businesses to create values for the community.',
  },
  Judges: {
    tit: 'Best Practice Showcase Judging Panel',
    tip:
      'Best Practices are chosen by an independent judging panel which comprises well-respected leaders of industry, community, experts from the business and financial sectors.',
    tip_2: 'Best Practices are chosen by an independent judging panel which comprises well-respected leaders of industry, community, experts from the business and financial sectors. Coming to the second session, we have 13 judges on the panel.',
    name1: 'Ms Fang Meng-sang, Christine',
    name2: 'Mr Kenneth Fok',
    name3: 'Dr Fung Hong',
    name4: 'Dr The Honourable Lam Ching-choi',
    name5: 'Dr Trisha Leahy',
    name6: 'Dr Lo Wing Yan William',
    name7: 'Ms Sarah Mui',
    name8: 'Mr Joe Ngai',
    name9: 'Mr James E. Thompson',
    name10: 'Mr Patrick Tsang',
    name11: "Mr Gilbert Lee",
    txt: '(Surnames in Alphabetical Order)',
    txt1: 'BBS, JP',
    txt2: 'JP',
    txt3: 'JP',
    txt4: 'SBS, JP',
    txt5: 'PhD, JD, SBS, BBS',
    txt6: 'JP',
    txt7: '<br>',
    txt8: '<br>',
    txt9: 'GBS',
    txt10: '<br>',
    txt11:'<br>',
    de1:
      'Founder-director of Governance and Management Excellence for Public Benefit (GAME)',
    de2:
      'Vice-President of the Sports Federation & Olympic Committee of Hong Kong, China',
    de3:
      'Executive Director and Chief Executive Officer of the CUHK Medical Centre',
    de4: 'Non-Official Member, the Executive Council of the HKSAR Government',
    de5: 'Chief Executive of the Hong Kong Sports Institute',
    de6:
      'Independent Non-executive Director & Audit Committee Chairman of Television Broadcasts Limited',
    de7: 'Design Director, One Bite Design Studio',
    de8:
      'Senior Partner and Managing Partner, Greater China, McKinsey & Company',
    de9: 'Chairman and Founder of the Crown Worldwide Group',
    de10: 'CEO, Chow Tai Fook Enterprises',
    de11:'Head of Strategy & Planning and Chief of Staff to CE, Hang Seng Bank',
    // judges 2023 start
    new_name1:'Professor Cecilia L.W. Chan',
    new_txt1 : 'PhD, RSW, FHKPCA, FHKASW, FAOSW,  JP',
    new_de1: 'Professor Emeritus, Department of Social Work and Social Administration, The University of Hong Kong',
    new_name2:'Professor Sophia Siu Chee',
    new_txt2 : 'GBS, JP',
    new_de2: '<p style="font-size:14px">Professor in Nursing, The University of Hong Kong</p><p style="font-size:14px">Senior Advisor to the President’s Office The University of Hong Kong</p>',
    new_name3:'Mr Alan Cheung',
    new_txt3 : '',
    new_de3: 'Co-founder & Managing Director One Bite Design Studio',
    new_name4:'Dr Fung Hong',
    new_txt4 : 'JP',
    new_de4: 'Executive Director and Chief Executive Officer, CUHK Medical Centre',
    new_name5:'Dr The Honourable Lam Ching-choi',
    new_txt5 : 'SBS, JP',
    new_de5: 'Non-Official Member, the Executive Council of the HKSAR Government',
    new_name6:'Dr Trisha Leahy',
    new_txt6 : 'PhD, JD, SBS, BBS',
    new_de6: 'Chief Executive, the Hong Kong Sports Institute',
    new_name7:'Mr Gilbert Lee',
    new_txt7 : '',
    new_de7: 'Head of Strategy & Planning and Chief of Staff to CE, Hang Seng Bank',
    new_name8:'Dr Lo Wing Yan William',
    new_txt8 : 'JP',
    new_de8: 'Founder & Chairman, Da Z Group Co Limited',
    new_name9:'Ms Kris Lui',
    new_txt9 : 'FIHRM (HK)',
    new_de9: 'Executive Council Member, Hong Kong Institute of Human Resources Management',
    new_name10:'Mr Joe Ngai',
    new_txt10 : 'JP',
    new_de10: 'Senior Partner, Chairman of Greater China, McKinsey & Company',
    new_name11:'Ms Jenny Pong',
    new_txt11 : '',
    new_de11: 'People Director — East Asia Arup',
    new_name12:'Mr James E. Thompson',
    new_txt12 : 'GBS',
    new_de12: 'Chairman and Founder,  the Crown Worldwide Group',
    new_name13:'Mr Patrick Tsang',
    new_txt13 : '',
    new_de13: 'CEO, Chow Tai Fook Enterprises',
  },
  Details: {
    table: 'Application Form',
    title: 'To be a Successful Recipient',
    header: 'Judging Criteria',
    tip:
      'The SportsHour Company Scheme 2023-25 will receive applications from 1 April to 30 June. Start building your active team today!',
    table_tit: 'Company Size',
    employees1: '<50 employees',
    employees2: '50-500 employees',
    employees3: '>500 employees',
    basic_scopes: 'Basic Scopes',
    practices_showcase: 'Best Practices Showcase',
    table_header_1: 'Scope 1',
    table_header_2: 'Scope 2',
    table_header_3: 'Scope 3',
    table_header_4: 'Total Required Items',
    table_header_5: 'Additional Required Item',
    scope1: 'Scope A',
    scope2: 'Scope B',
    scope3: 'Scope C',
    total: 'Total Required Items',
    txt1:
      "To be considered on the Best Practice Showcase, companies or organisations must first fulfil the basic requirements of being part of the SportsHour Company Scheme and the following minimum requirements on the thematic scope <span style='color:#1E30F6'>“CO-FIT”</span> according to company size:",
    txt2: '',
    cofit1: "<span style='color:#1E30F6'>“CO-FIT”</span>",
    cofit2: ' Best Practice Showcase Thematic Scope',
    scope: 'SCOPE',
    scope1_tit: 'ACTIVE Physical Environment',
    scope1_txt:
      'Contributes to the promotion of physical activity through helping employees create an at-home environment conducive to an active lifestyle.',
    scope2_tit: 'ACTIVE Social Environment',
    scope2_txt:
      'Offers alternative resources for staff to continue sports involvement during or off office hours, thus promoting work-life balance.',
    scope3_tit: 'ACTIVE Community Engagement',
    scope3_txt:
      'Engaging with local organisations, charities and communities to support athletes and foster an active community.',
    scope_footer_tit1: 'Best Practice Showcase Thematic Scope:',
    scope_footer_tit2:
      "<span style='color:#1E30F6;font-weight: 600;'>“CO-FIT”</span>",
    scope_footer_txt:
      'Company’s response to COVID-19 in consideration of their employees’ physical activity level and the community. ',
    join_tit:
      '*Please refer to the application form for detailed requirements of each scope.',
    apply: 'Click here to download the application form',
    join_date: 'Application Method and Deadline',
    join_tip:
      'Inaugural SportsHour Company Scheme was ended. The application details  for next term will be announced in 2023. Stay tuned!',
    email: 'sportshourcompany@inspiringhk.org',
    address1:
      "Unit 911, 9/F, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay",
    address2:
      '(Please also write “SportsHour Company application form”on the envelope)',
    apply_detail:
      '*Please refer to the application form for detailed requirements of each scope.',
    signup: 'Sign Up Now (Online Form)',
    download: 'Download Scheme Leaflet',
    event: 'Events and Seminars',
    best_practice_title: 'Best Practices Showcase Thematic Scope',
    best_practice_slogan: '"ProFITional Body, ProFITional Mind"',
    best_practice_content: 'To spark creativity in incorporating physical activities into maintaining mental wellness, so as to build an energetic and fulfilling team.',
    success_recipient_1: 'To encourage companies or organisations of different sizes to build an active team, companies and organisations would be considered as successful recipients of the SportsHour Company Scheme if the minimum requirements of the three basic scopes according to company size are fulfilled.',
    success_recipient_2_1: 'To be considered on the Best Practices Showcase, companies or organisations must first fulfill the basic requirements of being part of the SportsHour Company Scheme and the following minimum requirements on the thematic scope “',
    success_recipient_2_2: 'ProFITional Body, ProFITional Mind',
    success_recipient_2_3: '” according to company size. The details of the scopes could be found in our leaflet.',
  },
  CompanyList: {
    complist_tit1: 'Inaugural	SportsHour Company	Scheme	Recognised Company	List',
    complist_tit2: 'Companies	<50	employees',
    complist_tit2_1: 'in	alphabetical	order',
    complist_tit3: 'Organisations <50	employees',
    complist_tit3_1: 'in	alphabetical	order',
    complist_tit4: 'Companies	of	51	- 500	employees',
    complist_tit4_1: 'in	alphabetical	order',
    complist_tit5: 'Organisations	of	51	- 500	employees	',
    complist_tit5_1: 'in	alphabetical	order',
    complist_tit6: '	Companies	>500	employees',
    complist_tit6_1: 'in	alphabetical	order',
    complist_tit7: 'Organisations	>500	employees',
    complist_tit7_1: 'in	alphabetical	order',
    complist_tit1_2023: 'SportsHour Company Scheme 2023-25 Recognised Company / Organisation List',
    topTitle: '"ProFITional Body, ProFITional Mind" Best Practices Showcase'
  },
  COFITList: {
    co_tit1: '“CO-FIT”	Best	Practice	Showcase	Recognised	Companies	/	Organisations',
    co_tit2: 'in	alphabetical	order',
    co_tit3: 'Companies	or	organisations	<50	employees',
    co_tit4: 'Companies	or	organisations	with	50	- 500	employees',
    co_tit5: 'Companies	or	organisations	>500	employees',
  },
  COFITcompany: {
    title: '"ProFITional Body, ProFITional Mind" Best Practices Showcase',
    close: 'CLOSE',
    company_name1: 'Asian Academy for Sports & Fitness Professionals Limited',
    company_vid_desc_1: 'AASFP provides fitness products & resources for workers. Colleagues can choose a yoga ball instead of a chair. There were also unique Fitograph got published every week to share the fitness information and encourage colleagues to do more exercise and understand more about the information. Between February and April in 2020, our company activated free workshops for communities to educate 30 fitness trainers on how to teach people to do exercise in the park and have held voluntary fitness classes through online and offline. ',
    company_name2: 'Hong Kong Rope Skipping Federation, China Limited',
    company_vid_desc_2 : 'During the covid-19 pandemic period, the Hong Kong Rope Skipping Federation, China to keep employees exercising adequately and maintaining physical and mental health, we allow employees to do different kinds of exercise according to their interests, such as rope skipping, dancing and gymnastics, to relieve stress during work. Also, to reduce the opportunity of sharing equipment and facilities with the public, HKRSF specially purchased different sports equipment, such as a mini football gantry, table tennis suit, boxing sandbag and mini basketball suit.',
    company_name3: 'Terra Forma Limited',
    company_vid_desc_3 : 'Starting from last year, we have decided to do regular internal workout sessions for the staff on every Tuesday and Thursday before lunch so everyone can stay active even when the gym was closed before. We also provide uniform sponsorship to every employee. Each of them will receive a free pair of shoes every month.For the community, we have a Sunday Workout every week and it is open to the public free of charge. They will only need to sign-up online for workouts hosted by LANE EIGHT Trainers.',
    company_name4:
      'adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
    company_vid_desc_4 : 'Our key to unlocking this strategy was through virtual/digital platforms that allowed us to still connect as an organisation with employees and the community at large. For our employees, we were to take our in-office exercise class schedule onto a digital platform where they could join virtually. We tried to make our activities as inclusive as possible to bring the office closer together. One of these challenges was our ‘step challenge’ , which was a department competition to see which department could clock the most steps over a set period.',
    company_name5: 'Blue Cross (Asia-Pacific) Insurance Limited',
    company_vid_desc_5 : ' In consideration of the physical and mental health of employees, Blue Cross adopted different measures to support them in continuing to participate in healthy physical activities, such as organising online fitness workshops and online athlete sharing, etc. We also provide employees monthly well-being time off so that they can flexibly arrange more activities besides work. ',
    company_name6: 'GlaxoSmithKline Limited',
    company_vid_desc_6 : '<p>GSK has organised many engaging activities during COVID under the theme of “resilience” to promote physical and mental wellness and resilience to achieve “CO-FIT”: </p></br><ul><li>Set up targeted company policies and guidelines including “Resilience Time” which allowed monthly and festive early release, and flexi-work arrangements</li><li>Organised company-wide sports activities under “Resilience Friday” to provide online fitness classes including full body stretching, pilates for healthy back and funky dance</li><li>Gifted Virgin Pulse GO to employees to promote health training </li></ul>', 
    company_name7: 'Jebsen and Company Limited',
    company_vid_desc_7 : 'In light of the COVID-19 pandemic, our staff engagement initiatives have gone online since last year. A case in point was our annual Wellness Month – instead of arranging on-site check-ups, we distributed fitness sets and circulated workout videos to colleagues across the Group. Partnering with InspiringHK Sports Foundation (IHKSports), we produced four videos with different themes and levels of difficulty for our colleagues to follow weekly and make full use of the equipment in the comfort of their home as they worked remotely. ',
    company_name8: 'The Hong Kong and China Gas Company Limited',
    company_vid_desc_8: 'In view of social-distancing measures, Towngas moved offline health activities online. The Company also launched a series of short videos for home fitness courses to facilitate at-home exercise for our employees and their family.The Company’s flexible working hour policy allows colleagues to take their lunch breaks at different period of time and it facilitates their use of the in-office physical activity area and equipment.',
    company_name9: 'The Kowloon Motor Bus Company (1933) Limited',
    company_vid_desc_9 : '<p>Due to the COVID-19 pandemic, KMB promotes doing exercise through online channels with the integration of creativity and technology, including:</p><br><ul style="list-style:none"><li>- Organised “Plank Challenge” and online health talk on effective weight control to encourage employees to maintain regular exercises and pay attention to a balanced diet;</li><li>- Hiking Club, a new interest club, has been set up to encourage staff going hiking. We also published e-booklets and video clips to introduce hotspots of the countryside</li><li>- Took part in SPS virtual charity walk; and</li><li>- Produced WhatsApp stickers “Fight against the epidemic together”.</li></ul>',
    company_name10: 'The University of Hong Kong',
    company_vid_desc_10: 'During the peak of the COVID-19 pandemic in 2020, through the Exercise is Medicine on Campus platform (EIM-OC) (www.eim.cse.hku.hk/), it distributed flyers on home/office-stretching exercises and various online training videos featuring exercises of different intensity levels for individuals of varying fitness levels and abilities. Full-time staff and their dependent family members were offered free access to the sports facilities.',
    employees_50: 'Companies or organisations <50 employees',
    employees_500: 'Companies or organisations with 50 - 500 employees',
    employees_5000: 'Companies or organisations >500 employees',
  },
  COFITcompany2023: {
    company_name1: 'Asian Academy for Sports & Fitness Professionals Limited',
    company_vid_desc_1: 'Asian Academy for Sports & Fitness Academy (AASFP), founded more than 30 years ago, is dedicated to the growth and development of the professional sports and fitness industry through internationally recognised certification courses, workshops, seminars, conferences and continuing education programmes.',
    company_name2: '​​Kai Tak Sports Park',
    company_vid_desc_2: 'Kai Tak Sports Park offers a variety of sports activities at the office for colleagues to play and unwind anytime all day! Our colleagues can stretch their bodies and minds in our office gym. We also give out event tickets to colleagues, to enable them to spend time with relatives and let family members know about their work, helping enhance family cohesion and reduce pressure in life.',
    company_name3: 'Time Auction Limited',
    company_vid_desc_3: 'Time Auction is a charity advocating for volunteerism. Externally, we promote volunteering through inspiring experiences. Internally, we focus on fostering a positive and vibrant workplace that emphasizes the holistic well-being of our employees, which is crucial for their personal and professional growth. Recognizing the undeniable benefits of physical activity for both the mind and body, we regularly organise sports and relaxation events. These initiatives help our team counteract work-related stress, bolstering their overall self-worth and happiness. As a testament to our dedication, sports and physical activities remain core components of our organizational culture.',
    company_name4: 'adidas (adidas Sourcing Limited & adidas Hong Kong Limited)',
    company_vid_desc_4: 'The adidas culture is shaped by our purpose, “Through Sport, We Have the Power to Change Lives”. We offer a variety of wellbeing programs to help our employees stay fit, energized and engaged. During office hours, we provide a wide range of sports activities, including yoga, pilates, dancing, kickboxing, and volleyball. We also offer experience classes such as ice skating, bowling and bouldering to widen employees’ exposure to new sports. To provide flexibility, we offer allowance for wellbeing expenses outside the office, such as external sports classes, sports equipment and more.',
    company_name5: 'Hanison Construction Holdings Limited',
    company_vid_desc_5: 'Hanison established various committees in the early stage of development. In addition to playing a dedicated role, it also takes full care of the physical and mental health of employees.The Group takes care of the holistic and basic well-being of our employees, and also invests in talent development and nurturing. Under the leadership of management, the Group encourages employees to participate in various activities that are beneficial to the body and mind, establishes sports teams such as table-tennis and football, and employs local sports coaches to provide professional training. At the same time, it also subsidises sports teams to participate in public competitions and appreciates award-winning employees to enhance their engagement and build teamwork and partnership spirit.',
    company_name6: 'Hogan Lovells',
    company_vid_desc_6: 'The Hong Kong office of Hogan Lovells has embraced the global wellbeing strategy. We have participated in activities from exciting competitions like our international dragon boat races, basketball tournaments and in-house Olympic mini-games, to activities such as Pilates and yoga classes and onsite massages. During the Covid pandemic, we provided training resistance bands to each colleague to continue exercising at home. When we transitioned back to the office, Pilates and yoga classes have been very popular.',
    company_name7: 'Tesla Motors HK Limited',
    company_vid_desc_7: 'Our greatest asset is our people, and we strive to be a workplace where people love to come to work every day. Over the years, we encourage employees to maintain a healthy lifestyle in order to continue innovating and changing the world for the better. In the past 2 years, we initiated multiple innovative staff engagement activities to proactively promote all aspects of exercise and fitness. We organized multiple physical activities including plank challenge, step count challenge, Workout Relay, Tesla Center Run etc. that vary from individual to group challenges, physical to virtual activities. Last year, we even incorporated the interactive AR fitness mobile apps into our fitness challenge to enrich the experience and make physical activities even more interesting. ',
    company_name8: 'CLP Power Hong Kong Limited',
    company_vid_desc_8: '“Energize Your Life” is one of CLP Power’s core values and our employees’ well-being is of paramount importance. We believe employees’ physical and mental health are inextricably bound to their work performance. Therefore, CLP Power provides a diverse range of sports programmes and wellness activities for encouraging colleagues to make exercise a regular habit in personal lives in order to boost up vitality and positive energy.',
    company_name9: 'DBS Bank (Hong Kong) Limited',
    company_vid_desc_9: 'At DBS, we adopt a holistic well-being strategy which is categorised as <strong> Best of ME, Best WE & Best of E(nvironment) </strong> to offer a full range of well-being supports to ensure our people <strong> feel cared for, invested in, valued for and connected.</strong> We regularly review our programmes to allow a comprehensive suite of support to fit the diverse needs of our people.  We put a strong focus on employees" physical and mental well-being under the Best of ME to empower our colleagues to take charge of their own well-being.',
    company_name10: 'The Hongkong and Shanghai Banking Corporation Limited',
    company_vid_desc_10: 'HSBC always treats our employees’ health and wellbeing as a top priority given their fundamental importance in enabling our workforce to thrive and be at their very best. We adopt a holistic approach on employee wellbeing covering 4 pillars – physical, mental, social and financial, recognising these dimensions, especially physical and mental health, are interconnected and crucial for one to achieve total wellbeing.'
  },
  Activities: {
    button: 'Download brochure here',
    title1: '《SportsHour Company Sharing (1) 》',
    title_cont:
      'Two online webinars were successfully held on April 16 and May 7 respectively. More than 200 representatives from 160 companies and organisations participated in the sessions to discuss How to "Sportify" Your Company and CO-FIT under COVID, establishing a new dynamic corporate culture under the new normal.',
    content_one1: `IHKSports thanks all judges and guests for taking time off from their busy schedule to join us, including Mr. Kenneth Fok, the Vice-President of the Sports Federation & Olympic Committee of Hong Kong China, InspiringHK Sports Foundation Athlete Ambassador Mr. Alex Fong , Dr. Fung Hong, Chief Executive Officer of CUHK Medical Centre,Ms. Sara Ho, Jebsen Group's Human Resources Director, Ms. Sarah Mui, One Bite Design Studio Design Director, Mr.Joe Ngai, Managing Partner of McKinsey Greater China, Ms. Malina Ngai, Group COO of A.S. Watson Group & CEO of A.S. Watson (Asia & Europe), Mr. Louis Pong, CEO of Employers' Federation of Hong Kong, Mr. Patrick Tsang, CEO of Chow Tai Fook Enterprises, Mr. Jeff Wong, LinkedIn Head Of North Asia Talent, Learning & Employees Engagement Solutions. `,
    content_one2:
      "In the first event, guests shared many practical cases and the importance ofs' listening. For example, creative ideas such as “walking meeting” and “deskercise”. ",
    content_one3:
      'Despite not knowing what the new normal will look like in the near future, Mr.Joe Ngai, managing partner of McKinsey Greater China, has pointed out COVID, like sports, is the ultimate test of resilience and the survival of the fittest, no matter the size of the company or organisation.',
    content_one4:
      'While hybrid events and technology plays a huge role moving forward, suggested by Mr. Kenneth Fok, the Vice-President of the Sports Federation & Olympic Committee of Hong Kong China. In the era of Slashies, Joe has also raised the importance of achieving Sports / Life / Work, improving the quality of living and work efficiency together!',
    title2: '《SportsHour Company Sharing (2) 》',
    title3:
      'Inaugural SportsHour Company Scheme and Best practice Showcase Recognition Ceremony',
    title3_txt:
      'Inaugural ceremony was successfully held at Chaiwan Youth Square on 9th September, 2021. Over 100 companies and organisations from different industries applied and in the end 99 were successfully being recognised. The ceremony gathered representatives of over 250 management personnel, joined by Dr The Honourable LAM Ching-choi, SBS, JP, Non-Official Member, the Executive Council of the HKSAR Government,  Dr Bernard Chan Pak-li, Under Secretary for Commerce and Economic Development, Mr Patrick Tsang, Chief Executive Officer of Chow Tai Fook Enterprises, Mr Joe Ngai, Senior Partner and Managing Partner, Greater China, McKinsey & Company and Mr Boyce Lai Chun-ho, former Hong Kong Team Track and Field Athlete as award presentation guests.',
    title3_content1:
      'More than 250 pioneers of SportsHour Company gathered to promote the importance of active living to all business sectors.',
    title3_content2:
      'Senior leaders also wore a sports casual outfit to attend the recognition ceremony for a change, showing off their active side!',
    title3_content3:
      'Dr. Lam Ching-choi, a non-official member of the Executive Council, is a "cycling enthusiast". After the event, he also strongly supported the "exercising one hour a day" initiative on his personal social media handle.',
    title3_content4:
      'The Deputy Secretary for Commerce and Economic Development, Dr. Bernard Chan, said that the government will continue to encourage citizens to exercise more through different platforms.',
    title4: ' Scheme Promotion',
  },
  Ceremony: {
    title:
      'Inaugural ceremony was successfully held at Chaiwan Youth Square on 9th September, 2021. Over 100 companies and organisations from different industries applied and in the end 99 were successfully being recognised. The ceremony gathered representatives of over 250 management personnel, joined by Dr The Honourable LAM Ching-choi, SBS, JP, Non-Official Member, the Executive Council of the HKSAR Government, Dr Bernard Chan Pak-li, Under Secretary for Commerce and Economic Development, Mr Patrick Tsang, Chief Executive Officer of Chow Tai Fook Enterprises, Mr Joe Ngai, Senior Partner and Managing Partner, Greater China, McKinsey & Company and Mr Boyce Lai Chun-ho, former Hong Kong Team Track and Field Athlete as award presentation guests.',
    btn: 'Download brochure',
    btn_watch: 'Watch video',
    title_23_25:
      'The SportsHour Symposium and Recognition Ceremony 2023-25 was successfully held at HKEX Connect Hall on 25th, September 2023. To conclude, 136 organisations and companies have been recognised under the Scheme 2023-25 with a 140% growth from the inaugural scheme. The ceremony gathered representatives of over 250 management personnel, joined by the following Honourable Guests.<br>\
      <br>\
      <b>Guest of Honour</b><br>\
      •	Mr Lester Huang, SBS JP - Steward of The Hong Kong Jockey Club<br>\
      <br>\
      <b>Penallists</b><br>\
      •	Ms Alison Chan - Chief Operating Officer, Chow Tai Fook Enterprises<br>\
      •	Ms Rebecca Chan - Managing Director, KOS International Limited<br>\
      •	Ms Mei Mei Ng - General Manager, LinkedIn Hong Kong Limited<br>\
      <br>\
      <b>Prize Presentation Guests</b><br>\
      •	Professor Sophia Siu Chee CHAN, GBS, JP - Professor in Nursing, HKU & Senior Advisor to the President’s Office, HKU<br>\
      •	Ms Kris Lui, F.I.H.R.M. (HK) - Executive Council Member, Hong Kong Institute of Human Resources Management<br>\
      •	Ms Jenny Pong - People Director — East Asia Arup<br>',
    download_brochure: 'Download brochure'
  },
  Seminar: {
    subTitle: 'Two online webinars were successfully held on April 16 and May 7 respectively. More than 200 representatives from 160 companies and organisations participated in the sessions to discuss How to "Sportify" Your Company and CO-FIT under COVID, establishing a new dynamic corporate culture under the new normal.',
    subTitle1: '《SportsHour Company Sharing (1) 》',
    content:'In the first event, guests shared many practical cases and the importance of employees\' listening. For example, creative ideas such as “walking meeting” and “deskercise”.',
    captions:{
      content_one1: `IHKSports thanks all judges and guests for taking time off from their busy schedule to join us, including Mr. Kenneth Fok, the Vice-President of the Sports Federation & Olympic Committee of Hong Kong China, InspiringHK Sports Foundation Athlete Ambassador Mr. Alex Fong , Dr. Fung Hong, Chief Executive Officer of CUHK Medical Centre,Ms. Sara Ho, Jebsen Group's Human Resources Director, Ms. Sarah Mui, One Bite Design Studio Design Director, Mr.Joe Ngai, Managing Partner of McKinsey Greater China, Ms. Malina Ngai, Group COO of A.S. Watson Group & CEO of A.S. Watson (Asia & Europe), Mr. Louis Pong, CEO of Employers' Federation of Hong Kong, Mr. Patrick Tsang, CEO of Chow Tai Fook Enterprises, Mr. Jeff Wong, LinkedIn Head Of North Asia Talent, Learning & Employees Engagement Solutions. `,
      content_one2:
          "In the first event, guests shared many practical cases and the importance ofs' listening. For example, creative ideas such as “walking meeting” and “deskercise”. ",
      content_one3:
          'Despite not knowing what the new normal will look like in the near future, Mr.Joe Ngai, managing partner of McKinsey Greater China, has pointed out COVID, like sports, is the ultimate test of resilience and the survival of the fittest, no matter the size of the company or organisation.',
      content_one4:
          'While hybrid events and technology plays a huge role moving forward, suggested by Mr. Kenneth Fok, the Vice-President of the Sports Federation & Olympic Committee of Hong Kong China. In the era of Slashies, Joe has also raised the importance of achieving Sports / Life / Work, improving the quality of living and work efficiency together!',
    },
    subTitle2: '《SportsHour Company Sharing (2)  – CO-FIT in COVID》',
    content2: 'The pandemic has greatly shifted our work culture. We stay home for a longer time but exercise for a shorter time. To keep colleagues active in this period posed an even greater challenge for companies and organisations. This online seminar therefore targeted at sparking creativity in the industry, by having leaders from the public, corporate and academic sector on the panel to share their experiences, to create an active work atmosphere in the team.',
    signup: 'Sign Up Now',
    text_23_25: 'To explore the role sports can play in a company\'s management and development, profound guest speakers from the sport industry, human resources institution, technology sector and corporations were featured in our thought leadership series webinars. Free sports tasting sessions within office hours were also provided as refreshing experiences for one to stay active while working.',
    title_23_25 :'Apr to Jun 2023',
    subtitle_sport :'Sports Tasting Sessions(7 sessions)',
    subtitle_leadership: 'Thought Leadership Series (3 sessions)'
  },
  Feature: {
    fea_txt1: 'Karen Cheng - Exercise While Working',
    fea_media1: 'AM730',
    fea_date1: 'April 07 , 2021',
    fea_txt2:
      '  Organisation establishes sports award scheme to encourage sports culture in workplace, corporate allows employees off work earlier for exercise ',
    fea_media2: 'HK01',
    fea_date2: 'April 09 , 2021',
    fea_txt3: ' How to “Sportify” Work Culture',
    fea_media3: 'Headline News',
    fea_date3: 'April 09 , 2021',
    fea_txt4:
      ' Corporate organises sports activities, Kenneth Fok affirms “facilitates employee communication” ',
    fea_media4: 'SingTao Daily',
    fea_date4: 'April 10 , 2021',
    fea_txt5: ' The Transformation of Athletes',
    fea_media5: 'Headline Daily',
    fea_date5: 'September 10, 2021',
    fea_txt6:
      " Hong Kong and China Gas : Towngas recognised as 'CO-FIT' Best Practice Showcase Company for advocating sports culture for staff mental and physical health ",
    fea_media6: 'MarketScreener',
    fea_date6: 'September 10, 2021',
    fea_txt7:
      ' 99 Companies Recognised as a "SportsHour Company" ; Am730 Was One of the Awardee',
    fea_media7: 'am730 ',
    fea_date7: 'September 13, 2021',
    fea_txt8: ' SportsHour Company, Have You Exercised Today? ',
    fea_media8: 'Hong Kong Open TV',
    fea_date8: 'September 15, 2021',
    fea_txt9: 'SportsHour Company Scheme Opens for Application',
    fea_media9: 'i-Cable',
    fea_date9: 'March 30, 2023',
  },
  Together:{
    move_tog: ' If any corporate or organisation is interested in organising sports activities or classes through IHKSports, please contact Ms Wu at 56056013.',
    read: 'Read More'
  },
  sponsor:{
    title1: 'Lead Advocacy Partner',
    title2: 'Supporting Partner',
    title3: 'Digital Partner',
    title4: 'Learning Partner',
    title5: 'Media Partner',
    title6: 'Creative Partner',
    tips: '(in alphabetical order)',
  },
  kick_off: {
    desc: 'SportsHour Company Scheme 2023-25 was kicked off on 30th March! Thank the representatives from our lead advocacy partner Chow Tai Fook Enterprises and CTFE Social Solutions, the 10 Inaugural Best Practice Showcases, with the Olympian Badminton player Mr Tang Chun Man, for kicking off the Scheme with us.',
  },
  ...enLocale,
}
export default en
