<template>
  <div class="footer_bg">
    <div class="footer">
      <!-- <div class="footer-con">
        <div class="left">
          <div class="con">
            <p class="tit1">{{ " " + $t("Footer.footer1") }}</p>
            <img
              width="150"
              height="78px"
              src="@/assets/images/ihk-logo.47be4f54.svg"
              alt=""
              style="margin-left: 10px"
            />
          </div>
        </div>
        <div class="right">
          <div class="con">
            <p class="tit2">{{ "  " + $t("Footer.footer2") }}</p>
            <img
              width="180"
              height="62px"
              src="@/assets/images/image.png"
              alt=""
              style="margin-left: 10px"
              class="img2"
            />
          </div>
        </div>
      </div> -->
    </div>
    <div class="footer-line"></div>
  </div>
</template>

<script>
export default {
  name: "myFooter",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer_bg {
  .footer {
    height: 200px;
    // padding-top: 0px;
    // background: lightcoral;
    .footer-con {
      width: 40%;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-items: center;
      //  background: red;
      justify-content: space-around;
      .left,
      .right {
        flex: 1;
      }
      .left {
        border-right: 1px solid #d2d2d2;
        //  background: red;
        .con {
          width: 50%;
          text-align: left;
          float: left;
          .tit1 {
            width: 200px;
            height: 70px;

            i {
              width: 15px;
              height: 40px;
              display: inline-block;
              background: url("../assets/images/Group 11846.svg") no-repeat 100%/100%;
            }
          }
        }
      }
      .right {
        // background: blue;
        .con {
          width: 50%;
          text-align: left;
          float: right;
          i {
            width: 15px;
            height: 40px;
            display: inline-block;
            background: url("../assets/images/Group 11846.svg") no-repeat 100%/100%;
          }
          .tit2 {
            width: 200px;
            height: 90px;
          }
        }
      }
    }
  }
  .footer-line {
    height: 20px;
    background: #6ec9f5;
  }
}
@media screen and (max-width: 500px) {
  .footer_bg {
    width: 100%;
    margin: 10% auto 0;
    .footer {
      .footer-con {
        .left,
        .right {
          .con {
            text-align: center;
            float: none;
            width: 100%;
            .img2 {
              width: 140px;
              height: 50px;
            }
          }
        }
      }
    }
  }
}
</style>
